import React from "react";
import SendArrowIcon from "../SendArrowIcon";

const DebitMoneyIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-secondary-icon-bg rounded-full">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.75 8.4502C0.75 7.4837 1.5335 6.7002 2.5 6.7002H3.5C4.4665 6.7002 5.25 7.4837 5.25 8.4502V15.7229C5.25 15.8336 5.31759 15.9058 5.39205 15.9256C6.04309 16.0991 7.15209 16.3652 8.6233 16.6104C9.92602 16.8275 11.0246 16.5335 12.2336 16.0741C12.48 15.9805 12.7347 15.878 12.9981 15.772C13.994 15.3714 15.1134 14.9209 16.3767 14.7104C17.8351 14.4673 19.2806 14.7851 20.0737 15.0164C20.8177 15.2334 21.25 15.9191 21.25 16.6217V17.4346C21.25 18.5332 20.2641 19.3493 19.231 19.2701C18.8957 19.2444 18.5935 19.2248 18.345 19.2087C18.1885 19.1985 18.0534 19.1897 17.9447 19.1817C16.3551 19.0641 15.1509 19.5564 13.7886 20.1245L13.7488 20.1411C12.4095 20.6997 10.9119 21.3242 8.94468 21.1787C6.30852 20.9837 4.06952 20.4625 1.74882 19.3993C1.12503 19.1135 0.75 18.4901 0.75 17.8288V8.4502Z"
            fill="#F4F4F4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.75 8.4502C0.75 7.4837 1.5335 6.7002 2.5 6.7002H3.5C4.4665 6.7002 5.25 7.4837 5.25 8.4502V15.7229C5.25 15.8336 5.31759 15.9058 5.39205 15.9256C6.04309 16.0991 7.15209 16.3652 8.6233 16.6104C9.92602 16.8275 11.0246 16.5335 12.2336 16.0741C12.48 15.9805 12.7347 15.878 12.9981 15.772C13.994 15.3714 15.1134 14.9209 16.3767 14.7104C17.8351 14.4673 19.2806 14.7851 20.0737 15.0164C20.8177 15.2334 21.25 15.9191 21.25 16.6217V17.4346C21.25 18.5332 20.2641 19.3493 19.231 19.2701C18.8957 19.2444 18.5935 19.2248 18.345 19.2087C18.1885 19.1985 18.0534 19.1897 17.9447 19.1817C16.3551 19.0641 15.1509 19.5564 13.7886 20.1245L13.7488 20.1411C12.4095 20.6997 10.9119 21.3242 8.94468 21.1787C6.30852 20.9837 4.06952 20.4625 1.74882 19.3993C1.12503 19.1135 0.75 18.4901 0.75 17.8288V8.4502Z"
            fill="#50555C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3117 3.72072C14.7509 3.23334 16.3253 2.7002 18 2.7002C19.3744 2.7002 21.1604 3.21799 22.0705 3.51078C22.7936 3.74341 23.25 4.41658 23.25 5.14585V15.0629C23.25 16.2542 22.1017 17.0734 20.9917 16.7766C20.0591 16.5273 18.8534 16.2487 17.9447 16.1815C16.3551 16.0639 15.151 16.5563 13.7887 17.1244L13.7489 17.141C12.4095 17.6996 10.9119 18.3242 8.94467 18.1787C7.63627 18.0819 5.91459 17.6272 4.99223 17.3625C4.24217 17.1472 3.75 16.4605 3.75 15.7018V5.88342C3.75 4.65548 4.95645 3.84609 6.07225 4.16717C6.99622 4.43305 8.14485 4.69742 9 4.69742C10.4063 4.69742 11.7635 4.24495 13.2595 3.73842L13.3117 3.72072Z"
            fill="#F4F4F4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3117 3.72072C14.7509 3.23334 16.3253 2.7002 18 2.7002C19.3744 2.7002 21.1604 3.21799 22.0705 3.51078C22.7936 3.74341 23.25 4.41658 23.25 5.14585V15.0629C23.25 16.2542 22.1017 17.0734 20.9917 16.7766C20.0591 16.5273 18.8534 16.2487 17.9447 16.1815C16.3551 16.0639 15.151 16.5563 13.7887 17.1244L13.7489 17.141C12.4095 17.6996 10.9119 18.3242 8.94467 18.1787C7.63627 18.0819 5.91459 17.6272 4.99223 17.3625C4.24217 17.1472 3.75 16.4605 3.75 15.7018V5.88342C3.75 4.65548 4.95645 3.84609 6.07225 4.16717C6.99622 4.43305 8.14485 4.69742 9 4.69742C10.4063 4.69742 11.7635 4.24495 13.2595 3.73842L13.3117 3.72072Z"
            fill="url(#paint0_linear_6134_50138)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3117 3.72072C14.7509 3.23334 16.3253 2.7002 18 2.7002C19.3744 2.7002 21.1604 3.21799 22.0705 3.51078C22.7936 3.74341 23.25 4.41658 23.25 5.14585V15.0629C23.25 16.2542 22.1017 17.0734 20.9917 16.7766C20.0591 16.5273 18.8534 16.2487 17.9447 16.1815C16.3551 16.0639 15.151 16.5563 13.7887 17.1244L13.7489 17.141C12.4095 17.6996 10.9119 18.3242 8.94467 18.1787C7.63627 18.0819 5.91459 17.6272 4.99223 17.3625C4.24217 17.1472 3.75 16.4605 3.75 15.7018V5.88342C3.75 4.65548 4.95645 3.84609 6.07225 4.16717C6.99622 4.43305 8.14485 4.69742 9 4.69742C10.4063 4.69742 11.7635 4.24495 13.2595 3.73842L13.3117 3.72072Z"
            stroke="#898A8D"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M13.6883 5.17689C12.2491 5.66428 10.6747 6.19742 9 6.19742C8.2 6.44861 6.24706 5.94426 5.37058 5.66069C5.28972 5.71905 5.25 5.79842 5.25 5.88342V15.7018C5.25 15.8132 5.3197 15.8959 5.40598 15.9207C6.33214 16.1865 7.91876 16.5987 9.05533 16.6828C10.6449 16.8004 11.849 16.308 13.2113 15.7399L13.2511 15.7233L13.2512 15.7233C14.5905 15.1647 16.0881 14.54 18.0553 14.6856C19.1147 14.7639 20.4365 15.0755 21.3791 15.3275C21.5778 15.3807 21.75 15.2261 21.75 15.0629V5.14585C21.75 5.03744 21.6854 4.96262 21.6111 4.93871C20.6908 4.64266 19.111 4.2002 18 4.2002C16.5937 4.2002 15.2365 4.65267 13.7405 5.15919L13.6883 5.17689Z"
            stroke="#B5BBB3"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M10 10.4502C10 8.5172 11.567 6.9502 13.5 6.9502C15.433 6.9502 17 8.5172 17 10.4502C17 12.3832 15.433 13.9502 13.5 13.9502C11.567 13.9502 10 12.3832 10 10.4502Z"
            fill="#F4F4F4"
          />
          <path
            d="M10 10.4502C10 8.5172 11.567 6.9502 13.5 6.9502C15.433 6.9502 17 8.5172 17 10.4502C17 12.3832 15.433 13.9502 13.5 13.9502C11.567 13.9502 10 12.3832 10 10.4502Z"
            fill="#B5BBB3"
          />
          <path
            d="M10 10.4502C10 8.5172 11.567 6.9502 13.5 6.9502C15.433 6.9502 17 8.5172 17 10.4502C17 12.3832 15.433 13.9502 13.5 13.9502C11.567 13.9502 10 12.3832 10 10.4502Z"
            stroke="#CAD0C8"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.75 11.4502C6.75 11.036 7.08579 10.7002 7.5 10.7002H7.51C7.92421 10.7002 8.26 11.036 8.26 11.4502C8.26 11.8644 7.92421 12.2002 7.51 12.2002H7.5C7.08579 12.2002 6.75 11.8644 6.75 11.4502Z"
            fill="#B5BBB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.75 11.4502C6.75 11.036 7.08579 10.7002 7.5 10.7002H7.51C7.92421 10.7002 8.26 11.036 8.26 11.4502C8.26 11.8644 7.92421 12.2002 7.51 12.2002H7.5C7.08579 12.2002 6.75 11.8644 6.75 11.4502Z"
            fill="#B5BBB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.75 11.4502C6.75 11.036 7.08579 10.7002 7.5 10.7002H7.51C7.92421 10.7002 8.26 11.036 8.26 11.4502C8.26 11.8644 7.92421 12.2002 7.51 12.2002H7.5C7.08579 12.2002 6.75 11.8644 6.75 11.4502Z"
            stroke="#74736F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.75 9.4502C18.75 9.03598 19.0858 8.7002 19.5 8.7002H19.51C19.9242 8.7002 20.26 9.03598 20.26 9.4502C20.26 9.86441 19.9242 10.2002 19.51 10.2002H19.5C19.0858 10.2002 18.75 9.86441 18.75 9.4502Z"
            fill="#B5BBB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.75 9.4502C18.75 9.03598 19.0858 8.7002 19.5 8.7002H19.51C19.9242 8.7002 20.26 9.03598 20.26 9.4502C20.26 9.86441 19.9242 10.2002 19.51 10.2002H19.5C19.0858 10.2002 18.75 9.86441 18.75 9.4502Z"
            fill="#B5BBB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.75 9.4502C18.75 9.03598 19.0858 8.7002 19.5 8.7002H19.51C19.9242 8.7002 20.26 9.03598 20.26 9.4502C20.26 9.86441 19.9242 10.2002 19.51 10.2002H19.5C19.0858 10.2002 18.75 9.86441 18.75 9.4502Z"
            stroke="#50555C"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6134_50138"
              x1="13.5"
              y1="2.70019"
              x2="22.8384"
              y2="16.7983"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#AEACA4" />
              <stop offset={1} stopColor="#50555C" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 -right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <SendArrowIcon />
      </div>
    </div>
  );
};

export default DebitMoneyIcon;
