const ReportIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.37785 0.608317C3.76735 0.218818 4.29562 0 4.84645 0H9.69261C9.87622 0 10.0523 0.0729393 10.1821 0.202772L15.0283 5.04893C15.1581 5.17876 15.2311 5.35485 15.2311 5.53846V13.1538C15.2311 13.7047 15.0123 14.233 14.6228 14.6225C14.2333 15.012 13.705 15.2308 13.1541 15.2308H4.84645C4.29562 15.2308 3.76735 15.012 3.37785 14.6225C2.98835 14.233 2.76953 13.7047 2.76953 13.1538V2.07692C2.76953 1.52609 2.98835 0.997815 3.37785 0.608317ZM4.84645 1.38462C4.66284 1.38462 4.48675 1.45755 4.35692 1.58739C4.22709 1.71722 4.15415 1.89331 4.15415 2.07692V13.1538C4.15415 13.3375 4.22709 13.5135 4.35692 13.6434C4.48675 13.7732 4.66284 13.8462 4.84645 13.8462H13.1541C13.3378 13.8462 13.5138 13.7732 13.6437 13.6434C13.7735 13.5135 13.8465 13.3375 13.8465 13.1538V5.82522L9.40585 1.38462H4.84645Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.608317 3.37785C0.997815 2.98835 1.52609 2.76953 2.07692 2.76953H3.46154C3.64515 2.76953 3.33171 4.02431 3.46154 4.15415V13.8465C3.59137 13.9763 7.26923 14.3552 7.26923 14.5388H12.4615V15.9234C12.4615 16.4742 12.2427 17.0025 11.8532 17.392C11.4637 17.7815 10.9355 18.0003 10.3846 18.0003H2.07692C1.52609 18.0003 0.997815 17.7815 0.608317 17.392C0.218818 17.0025 0 16.4742 0 15.9234V4.84645C0 4.29562 0.218818 3.76735 0.608317 3.37785ZM2.07692 4.15415C1.89331 4.15415 1.71722 4.22709 1.58739 4.35692C1.45755 4.48675 1.38462 4.66284 1.38462 4.84645V15.9234C1.38462 16.107 1.45755 16.2831 1.58739 16.4129C1.71722 16.5427 1.89331 16.6157 2.07692 16.6157H10.3846C10.5682 16.6157 10.7443 16.5427 10.8742 16.4129C11.004 16.2831 11.0769 16.107 11.0769 15.9234V14.5388L3.46154 13.8465V4.15415H2.07692Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.69231 0C10.0747 0 10.3846 0.309957 10.3846 0.692308V4.84615H14.5385C14.9208 4.84615 15.2308 5.15611 15.2308 5.53846C15.2308 5.92081 14.9208 6.23077 14.5385 6.23077H9.69231C9.30996 6.23077 9 5.92081 9 5.53846V0.692308C9 0.309957 9.30996 0 9.69231 0Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.98535 7.96107C4.98535 7.65519 5.23332 7.40723 5.5392 7.40723H12.4623C12.7682 7.40723 13.0161 7.65519 13.0161 7.96107C13.0161 8.26695 12.7682 8.51492 12.4623 8.51492H5.5392C5.23332 8.51492 4.98535 8.26695 4.98535 7.96107Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.98535 10.9611C4.98535 10.6552 5.23332 10.4072 5.5392 10.4072H9.46227C9.76816 10.4072 10.0161 10.6552 10.0161 10.9611C10.0161 11.267 9.76816 11.5149 9.46227 11.5149H5.5392C5.23332 11.5149 4.98535 11.267 4.98535 10.9611Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default ReportIcon;