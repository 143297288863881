import React from "react";
import { useSelector } from "react-redux";

const Logo = ({ className }) => {
  const company = useSelector((state) => state.ownCompany.company);

  return (
    <div className={className}>
      { !company || !company.client_portal_light_logo || !company.client_portal_dark_logo ? (
        <svg
          // width="257"
          // height="72"
          viewBox="0 0 257 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.1214 11.0714C18.6175 20.4717 3.24981 37.8169 0.0159184 69.8514C0.0159184 69.8991 0 69.9439 0 69.9897H0.0159184H0.199942C1.84126 52.1829 16.823 38.2606 35.0416 38.2606C53.0453 38.2606 67.8748 51.8477 69.8056 69.3451C69.8384 69.4834 69.8534 69.6057 69.8534 69.729C69.8991 70.1419 69.928 70.5417 69.9439 70.9396C69.9608 70.9556 69.9757 70.9556 69.9906 70.9695V3.05176e-05C69.9906 3.05176e-05 51.9571 0.275566 34.1214 11.0714Z"
            fill="url(#paint0_linear_4247_35457)"
          />
          <mask
            id="mask0_4247_35457"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={1}
            width={70}
            height={71}
          >
            <path
              d="M0.015625 72C0.0315408 71.9831 0.0464583 71.9682 0.0772951 71.9522C0.0902267 71.2927 0.137975 70.6491 0.199649 69.9896C1.84096 52.1829 16.8227 38.2605 35.0413 38.2605C52.9276 38.2605 67.6806 51.6696 69.7655 69.0039C66.0114 36.9733 49.9066 20.0409 34.1212 11.0714C16.8844 1.27126 0.015625 1.01263 0.015625 1.01263V72Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_4247_35457)">
            <path
              opacity="0.9"
              d="M0.015625 72C0.0315408 71.9831 0.0464583 71.9682 0.0772951 71.9522C0.0902267 71.2927 0.137975 70.6491 0.199649 69.9896C1.84096 52.1829 16.8227 38.2605 35.0413 38.2605C52.9276 38.2605 67.6806 51.6696 69.7655 69.0039C66.0114 36.9733 49.9066 20.0409 34.1212 11.0714C16.8844 1.27126 0.015625 1.01263 0.015625 1.01263V72Z"
              fill="url(#paint1_linear_4247_35457)"
            />
          </g>
          <path
            d="M133.646 9.75446V48.771H127.652V27.5921L114.977 48.1304H114.93L102.233 27.5692V48.771H96.2402V9.75446H96.6899L114.954 37.5175L133.197 9.75446H133.646Z"
            className="fill-body-text"
          />
          <path
            d="M139.141 29.7243C139.141 40.5261 148 49.3862 158.851 49.3862C169.724 49.3862 178.631 40.5261 178.631 29.7243C178.631 18.8269 169.724 9.91908 158.875 9.91908C148 9.91908 139.141 18.8269 139.141 29.7243ZM145.183 29.6775C145.183 22.1205 151.316 15.9611 158.851 15.9611C166.383 15.9611 172.567 22.1205 172.567 29.6775C172.567 37.1858 166.383 43.3223 158.851 43.3223C151.316 43.3223 145.183 37.1858 145.183 29.6775Z"
            fill="url(#paint2_linear_4247_35457)"
          />
          <path
            d="M214.8 10.4886V49.4355H214.516L190.092 23.9206V48.8188H184.1V9.91965H184.408L208.784 35.3859V10.4886H214.8Z"
            className="fill-body-text"
          />
          <path
            d="M228.117 16.4581V26.6691H242.569V32.6375H228.117V48.8188H222.125V10.4887H244.867V16.4581H228.117Z"
            className="fill-body-text"
          />
          <path
            d="M249.611 10.5838H255.604V48.7717H249.611V10.5838Z"
            className="fill-body-text"
          />
          <path
            d="M250.535 53.1917H252.3V56.5358L254.24 53.1917H256.278L253.677 57.6669L256.699 62H254.551L252.3 58.7652V62H250.535V53.1917Z"
            className="fill-body-text"
          />
          <path
            d="M242.523 53.1917L247.807 58.0057V53.1917H249.572V62L244.288 57.1806V62H242.523V53.1917Z"
            className="fill-body-text"
          />
          <path
            d="M237.514 58.9182H238.896L238.208 57.3773L237.514 58.9182ZM236.721 60.6777L236.694 60.7432L236.126 62H235.825H235.754H234.618H234.246L238.016 53.6015L238.202 53.1917L238.393 53.6069L242.169 62H241.798H240.661H240.585H240.284L239.721 60.7378L239.694 60.6777H236.721Z"
            className="fill-body-text"
          />
          <path
            d="M231.826 56.0822C232.373 56.3227 232.821 56.6942 233.17 57.2024C233.52 57.716 233.7 58.2843 233.7 58.9182C233.7 59.7706 233.394 60.4974 232.793 61.0984C232.187 61.6995 231.46 62 230.613 62H227.531V58.9182V57.5958V55.8309V55.3937V53.1917H229.733C230.334 53.1917 230.854 53.4102 231.285 53.8419C231.717 54.2736 231.935 54.7927 231.935 55.3937C231.935 55.6342 231.897 55.8637 231.826 56.0822ZM229.296 55.8309H229.733C230.028 55.8309 230.176 55.6888 230.176 55.3937C230.176 55.0987 230.028 54.9511 229.733 54.9511H229.296V55.3937V55.8309ZM230.613 57.5958H229.733H229.296V58.9182V60.2351H230.613C230.974 60.2351 231.285 60.1094 231.547 59.8526C231.804 59.5958 231.935 59.2843 231.935 58.9182C231.935 58.5575 231.804 58.2461 231.547 57.9838C231.285 57.727 230.974 57.5958 230.613 57.5958Z"
            className="fill-body-text"
          />
          <path
            d="M218.723 53.1917H220.488V60.2351H224.007V62H218.723V53.1917Z"
            className="fill-body-text"
          />
          <path
            d="M213.711 58.9182H215.093L214.405 57.3773L213.711 58.9182ZM212.919 60.6777L212.891 60.7432L212.323 62H212.023H211.951H210.815H210.443L214.214 53.6015L214.399 53.1917L214.591 53.6069L218.367 62H217.995H216.858H216.782H216.481L215.919 60.7378L215.891 60.6777H212.919Z"
            className="fill-body-text"
          />
          <path
            d="M205.724 53.1917H207.489H209.691V54.9511H207.489V62H205.724V54.9511H203.521V53.1917H205.724Z"
            className="fill-body-text"
          />
          <path d="M200.82 62V53.1917H202.585V62H200.82Z" className="fill-body-text" />
          <path
            d="M195.904 53.1917C196.505 53.1917 197.074 53.3119 197.609 53.5414C198.145 53.7709 198.614 54.0878 199.019 54.4812L197.773 55.7271C197.248 55.2134 196.625 54.9511 195.904 54.9511C195.172 54.9511 194.549 55.2134 194.035 55.7325C193.522 56.2516 193.265 56.8746 193.265 57.5958C193.265 58.328 193.522 58.951 194.035 59.4646C194.549 59.9782 195.172 60.2351 195.904 60.2351C196.472 60.2351 196.986 60.0711 197.44 59.7433C197.888 59.4154 198.21 58.9892 198.396 58.4756H195.904V56.7161H198.396H199.418H200.221C200.276 56.9948 200.308 57.2898 200.308 57.5958C200.308 58.8089 199.877 59.8471 199.013 60.7105C198.15 61.5738 197.112 62 195.904 62C194.691 62 193.653 61.5738 192.79 60.7105C191.926 59.8471 191.5 58.8089 191.5 57.5958C191.5 56.3882 191.926 55.35 192.79 54.4867C193.653 53.6233 194.691 53.1917 195.904 53.1917Z"
            className="fill-body-text"
          />
          <path d="M188.801 62V53.1917H190.566V62H188.801Z" className="fill-body-text" />
          <path
            d="M183.8 53.1917C185.008 53.1917 186.046 53.6233 186.909 54.4867C187.773 55.35 188.204 56.3882 188.204 57.5958C188.204 58.8089 187.773 59.8471 186.909 60.7105C186.046 61.5738 185.008 62 183.8 62H182.035V53.1917H183.8ZM183.8 60.2351C184.521 60.2351 185.144 59.9782 185.663 59.4646C186.177 58.951 186.439 58.328 186.439 57.5958C186.439 56.8746 186.177 56.2516 185.663 55.7325C185.144 55.2134 184.521 54.9511 183.8 54.9511V60.2351Z"
            className="fill-body-text"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4247_35457"
              x1="69.9913"
              y1="35.4848"
              x2="0.000675826"
              y2="35.4848"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#005AB4" />
              <stop offset="0.00666528" stopColor="#005AB4" />
              <stop offset={1} stopColor="#0CB9F1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4247_35457"
              x1="0.0157215"
              y1="36.5061"
              x2="69.8054"
              y2="36.5061"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#45AE1B" />
              <stop offset="0.999669" stopColor="#5DCE27" />
              <stop offset="0.999999" stopColor="#5DCE27" />
              <stop offset={1} stopColor="#5DCE27" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_4247_35457"
              x1="139.141"
              y1="29.653"
              x2="178.631"
              y2="29.653"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#45AE1B" />
              <stop offset="0.99967" stopColor="#5DCE27" />
              <stop offset={1} stopColor="#5DCE27" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <>
          <img
            src={company.client_portal_light_logo}
            alt="light logo"
            className={className + " dark:hidden block"}
          />
          <img
            src={company.client_portal_dark_logo}
            alt="dark logo"
            className={className + " hidden dark:block"}
          />
        </>
      )}
    </div>
  );
};

export default Logo;
