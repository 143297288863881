const BlockCardIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 10.5H8.5"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6 6.39844H13.2C12.5373 6.39844 12 6.9357 12 7.59844V11.7984C12 12.4612 12.5373 12.9984 13.2 12.9984H21.6C22.2627 12.9984 22.8 12.4612 22.8 11.7984V7.59844C22.8 6.9357 22.2627 6.39844 21.6 6.39844Z"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4004 6.4V4C14.4004 3.20435 14.7165 2.44129 15.2791 1.87868C15.8417 1.31607 16.6047 1 17.4004 1C18.196 1 18.9591 1.31607 19.5217 1.87868C20.0843 2.44129 20.4004 3.20435 20.4004 4V6.4"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 15.5H8"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 16.03V16.11C22 19.62 21.11 20.5 17.56 20.5H6.44C2.89 20.5 2 19.62 2 16.11V9.89C2 7.38 2.89 6.5 6.44 6.5H8.5"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default BlockCardIcon;