import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const LoadingSpinner = () => {
  const company = useSelector((state) => state.ownCompany.company);
  const short_name = process.env.REACT_APP_COMPANY_SHORT_NAME;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 z-[50] flex items-center justify-center">
      <div className="relative flex items-center justify-center w-28 h-28 animate-breathing">
        <span className="absolute text-3xl font-extrabold text-[#70cc4a]">
          {company && company.short_name ? company.short_name : short_name}
        </span>
        <div className="w-full h-full rounded-full border-[8px] border-transparent border-t-[#70cc4a] animate-spin"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
