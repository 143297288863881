import React from "react";
import { NavigationButton, PrimaryButton } from "../../Buttons";
import { useNavigate } from "react-router-dom";

const OrderComplete = ({
  message,
  children,
  repeatTransaction,
  coinAmount,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start justify-start">
      <h3 className="text-sm font-bold text-body-text md:mb-4 mb-3 mt-4">
        Crypto Order completed
      </h3>
      <div className="flex items-center justify-center flex-col">
        <div
          className="flex flex-col shadow-box-shadow bg-primarycard-gradient stroke-1 stroke-dropdown-popup-bg items-start 
       w-full rounded-xl border border-divider-border"
        >
          <div className="w-full rounded-t-xl bg-primary-Icon-bg">
            <div className="flex justify-start px-6 py-4">
              <h3 className="text-sm font-normal text-primary-item tracking-[0.07px]">
                An amount of exactly{" "}
                <span className="font-semibold">{coinAmount}</span>
                {message}
              </h3>
            </div>
          </div>
          <div className="flex w-full mt-2 justify-start px-6 py-4">
            <div className="flex w-full flex-col space-y-4">{children}</div>
          </div>
        </div>
        <hr className="w-full mt-4 border-divider-border" />
        <div className="grid grid-cols-2 justify-between mt-4 md:w-full w-full space-x-1">
          <NavigationButton
            onClick={repeatTransaction}
            text="New Transaction"
          />
          <PrimaryButton
            onClick={() => navigate("/all-transactions")}
            text="View Transaction"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderComplete;
