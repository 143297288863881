import React, { useState, useEffect } from "react";
import Porfile from "./Profile/profile";
import Notification from "./Notification/Notification";
import Help from "./Help/Help";
import Mode from "./Mode";
import { Logo } from "../Icons";
import ProfileSidebar from "./Profile/ProfileSidebar";
import NotificationSidebar from "./Notification/NotificationSidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "../../redux/actions/home/UserInfoAction";
import { fetchUsersNotification } from "../../redux/actions/notification";
import { Link } from "react-router-dom";
import HelpModal from "../Modals/HelpModal";
import { HeaderSkeletons } from "../Skeleton";
import BalanceCertificate from "../Modals/BalanceCertificate";
import SwitchUserButton from "../Buttons/SwitchUserButton";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { userInfo } = useSelector((state) => state.userInfo);
  const { user } = useSelector((state) => state.user);
  const [HelpModalOpen, setHelpModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [balanceCertificate, setBalanceCertificate] = useState(false);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const getUserNotification = async () => {
    await dispatch(fetchUsersNotification());
  };

  useEffect(() => {
    if (userInfo) {
      getUserNotification();
    }
  }, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`sticky top-0 z-10 bg-opacity- ${
        isScrolled ? "backdrop-blur-lg" : "bg-transparent"
      }`}
    >
      <nav className="flex justify-between max-w-5xl mx-auto px-4 md:pb-[35px] md:pt-[45px] py-6">
        <Link to="/">
          <Logo className="text-gray-500 max-md:w-40 max-md:h-auto w-[257px] h-[71px]" />
        </Link>
        <div className="flex justify-around md:space-x-6 ">
          {userInfo ? (
            <>
              <div className="flex space-x-6 max-[768px]:space-x-3">
                <Mode />

                {/* Disktop Notification */}
                <div className="max-sm:hidden">
                  <Notification getUserNotification={getUserNotification} />
                </div>

                {/* Mobile Notification */}
                <div className="sm:hidden">
                  <NotificationSidebar
                    getUserNotification={getUserNotification}
                  />
                </div>
                <HelpModal
                  HelpModalOpen={HelpModalOpen}
                  setHelpModalOpen={setHelpModalOpen}
                >
                  <Help />
                </HelpModal>

                {user && user.isSwitch && (
                  <div className="max-sm:hidden">
                    <SwitchUserButton user={user} />
                  </div>
                )}

                {userInfo && (
                  <BalanceCertificate
                    balanceCertificate={balanceCertificate}
                    setBalanceCertificate={setBalanceCertificate}
                  />
                )}
              </div>

              {/* Disktop Profile */}
              <div className="max-sm:hidden">
                <Porfile
                  email={userInfo?.email}
                  userName={userInfo?.name}
                  profilePicture={userInfo?.profile_photo_url}
                  setBalanceCertificate={setBalanceCertificate}
                />
              </div>

              {/* Mobile Profile */}
              <div className="sm:hidden">
                <ProfileSidebar
                  setHelpModalOpen={setHelpModalOpen}
                  email={userInfo?.email}
                  userName={userInfo?.name}
                  profilePicture={userInfo?.profile_photo_url}
                  setBalanceCertificate={setBalanceCertificate}
                />
              </div>
            </>
          ) : (
            <HeaderSkeletons />
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
