import React from "react";
import {
  FormatBalance,
  FormatDate,
  GetTransactionsTypeIcon,
} from "../../../../helper";

const TransactionItem = ({
  transaction,
  activeTransaction,
  currencySymbol,
  type = "accounts",
}) => {
  return (
    <div
      className={`w-full p-4 dark:hover:bg-hover-state-bg hover:bg-icon-btn-hover bg-active-state-bg cursor-pointer ${
        activeTransaction && activeTransaction.id === transaction.id
          ? "bg-active-state-bg hover:bg-active-state-bg"
          : "bg-transparent"
      } rounded-3xl justify-between items-start inline-flex`}
    >
      <div className="grow shrink basis-0 self-stretch justify-start items-center gap-4 flex">
        {GetTransactionsTypeIcon(type, transaction.type)}

        <div className="flex-col justify-start items-start inline-flex">
          <p className="text-body-text line-clamp-1 max-[360px]:max-w-40 md:max-w-80 max-w-48 pr-5 text-base font-medium leading-normal tracking-tight">
            {transaction.remarks}
          </p>
          <div className="flex-col justify-center items-center gap-2.5 flex">
            <div className="text-center text-paragraph-text text-sm font-normal leading-tight tracking-tight">
              {FormatDate(transaction.date, "d m, yyyy")}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex items-center justify-center space-x-1 text-right text-base font-semibold leading-normal tracking-tight
        ${
          transaction.type === "Credit"
            ? "text-highlighter-green"
            : "text-highlighter-red"
        }
        `}
      >
        <span>{transaction.type === "Credit" ? "+" : "-"}</span>
        <span>{currencySymbol}</span>
        <span>{FormatBalance(transaction.amount, type === "crypto")}</span>
      </div>
    </div>
  );
};

export default TransactionItem;
