import React from "react";
import ReceiveArrowIcon from "../ReceiveArrowIcon";

const CreditMoneyIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-secondary-icon-bg rounded-full">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6883 5.17689C12.2491 5.66428 10.6747 6.19742 9 6.19742C7.91865 6.19742 6.59623 5.87882 5.65745 5.60868C5.55838 5.58017 5.45361 5.60076 5.37058 5.66069C5.28972 5.71905 5.25 5.79842 5.25 5.88342V15.7018C5.25 15.8132 5.3197 15.8959 5.40598 15.9207C6.33214 16.1865 7.91876 16.5987 9.05533 16.6828C10.6449 16.8004 11.849 16.308 13.2113 15.7399L13.2511 15.7233C14.5905 15.1647 16.0881 14.54 18.0553 14.6856C19.1147 14.7639 20.4365 15.0755 21.3791 15.3275C21.5778 15.3807 21.75 15.2261 21.75 15.0629V5.14586C21.75 5.03744 21.6854 4.96262 21.6111 4.93871C20.6908 4.64266 19.111 4.2002 18 4.2002C16.5937 4.2002 15.2365 4.65267 13.7405 5.15919L13.6883 5.17689ZM13.3117 3.72072C14.7509 3.23334 16.3253 2.7002 18 2.7002C19.3744 2.7002 21.1604 3.21799 22.0705 3.51078C22.7936 3.74341 23.25 4.41658 23.25 5.14586V15.0629C23.25 16.2542 22.1017 17.0734 20.9917 16.7766C20.0591 16.5273 18.8534 16.2487 17.9447 16.1815C16.3551 16.0639 15.151 16.5563 13.7887 17.1244L13.7489 17.141C12.4095 17.6996 10.9119 18.3242 8.94467 18.1787C7.63627 18.0819 5.91459 17.6272 4.99223 17.3625C4.24217 17.1472 3.75 16.4605 3.75 15.7018V5.88342C3.75 4.65548 4.95645 3.84609 6.07225 4.16717C6.99622 4.43305 8.14485 4.69742 9 4.69742C10.4063 4.69742 11.7635 4.24495 13.2595 3.73842L13.3117 3.72072Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6883 5.17689C12.2491 5.66428 10.6747 6.19742 9 6.19742C7.91865 6.19742 6.59623 5.87882 5.65745 5.60868C5.55838 5.58017 5.45361 5.60076 5.37058 5.66069C5.28972 5.71905 5.25 5.79842 5.25 5.88342V15.7018C5.25 15.8132 5.3197 15.8959 5.40598 15.9207C6.33214 16.1865 7.91876 16.5987 9.05533 16.6828C10.6449 16.8004 11.849 16.308 13.2113 15.7399L13.2511 15.7233C14.5905 15.1647 16.0881 14.54 18.0553 14.6856C19.1147 14.7639 20.4365 15.0755 21.3791 15.3275C21.5778 15.3807 21.75 15.2261 21.75 15.0629V5.14586C21.75 5.03744 21.6854 4.96262 21.6111 4.93871C20.6908 4.64266 19.111 4.2002 18 4.2002C16.5937 4.2002 15.2365 4.65267 13.7405 5.15919L13.6883 5.17689ZM13.3117 3.72072C14.7509 3.23334 16.3253 2.7002 18 2.7002C19.3744 2.7002 21.1604 3.21799 22.0705 3.51078C22.7936 3.74341 23.25 4.41658 23.25 5.14586V15.0629C23.25 16.2542 22.1017 17.0734 20.9917 16.7766C20.0591 16.5273 18.8534 16.2487 17.9447 16.1815C16.3551 16.0639 15.151 16.5563 13.7887 17.1244L13.7489 17.141C12.4095 17.6996 10.9119 18.3242 8.94467 18.1787C7.63627 18.0819 5.91459 17.6272 4.99223 17.3625C4.24217 17.1472 3.75 16.4605 3.75 15.7018V5.88342C3.75 4.65548 4.95645 3.84609 6.07225 4.16717C6.99622 4.43305 8.14485 4.69742 9 4.69742C10.4063 4.69742 11.7635 4.24495 13.2595 3.73842L13.3117 3.72072Z"
            fill="url(#paint0_linear_6134_50121)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 8.2002C2.36193 8.2002 2.25 8.31212 2.25 8.4502V17.8288C2.25 17.9286 2.30553 18.0044 2.3736 18.0356C4.50979 19.0143 6.57074 19.499 9.05532 19.6828C10.6449 19.8004 11.8491 19.3081 13.2114 18.74L13.2512 18.7234C14.5905 18.1648 16.0881 17.5403 18.0553 17.6858C18.1249 17.6909 18.244 17.6987 18.391 17.7084C18.66 17.726 19.0225 17.7497 19.3456 17.7745C19.4424 17.7819 19.5483 17.7467 19.6322 17.6722C19.7147 17.599 19.75 17.512 19.75 17.4346V16.6217C19.75 16.5697 19.7342 16.5286 19.7153 16.5019C19.6978 16.4771 19.6776 16.4634 19.6537 16.4564C18.9397 16.2482 17.7517 16.0019 16.6233 16.19C15.5373 16.371 14.6133 16.7417 13.6381 17.1329C13.3536 17.247 13.0648 17.3629 12.7664 17.4763C11.4753 17.9669 10.074 18.3729 8.3767 18.09C6.85292 17.836 5.69712 17.5592 5.00591 17.3751C4.24238 17.1717 3.75 16.4793 3.75 15.7229V8.4502C3.75 8.31212 3.63807 8.2002 3.5 8.2002H2.5ZM0.75 8.4502C0.75 7.4837 1.5335 6.7002 2.5 6.7002H3.5C4.4665 6.7002 5.25 7.4837 5.25 8.4502V15.7229C5.25 15.8336 5.31759 15.9058 5.39205 15.9256C6.04309 16.0991 7.15209 16.3652 8.6233 16.6104C9.92602 16.8275 11.0246 16.5335 12.2336 16.0741C12.48 15.9805 12.7347 15.878 12.9981 15.772C13.994 15.3714 15.1134 14.9209 16.3767 14.7104C17.8351 14.4673 19.2806 14.7851 20.0737 15.0164C20.8177 15.2334 21.25 15.9191 21.25 16.6217V17.4346C21.25 18.5332 20.2641 19.3493 19.231 19.2701C18.8957 19.2444 18.5935 19.2248 18.345 19.2087C18.1885 19.1985 18.0534 19.1897 17.9447 19.1817C16.3551 19.0641 15.1509 19.5564 13.7886 20.1245L13.7488 20.1411C12.4095 20.6997 10.9119 21.3242 8.94468 21.1787C6.30852 20.9837 4.06952 20.4625 1.74882 19.3993C1.12503 19.1135 0.75 18.4901 0.75 17.8288V8.4502Z"
            fill="#FDD671"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 8.2002C2.36193 8.2002 2.25 8.31212 2.25 8.4502V17.8288C2.25 17.9286 2.30553 18.0044 2.3736 18.0356C4.50979 19.0143 6.57074 19.499 9.05532 19.6828C10.6449 19.8004 11.8491 19.3081 13.2114 18.74L13.2512 18.7234C14.5905 18.1648 16.0881 17.5403 18.0553 17.6858C18.1249 17.6909 18.244 17.6987 18.391 17.7084C18.66 17.726 19.0225 17.7497 19.3456 17.7745C19.4424 17.7819 19.5483 17.7467 19.6322 17.6722C19.7147 17.599 19.75 17.512 19.75 17.4346V16.6217C19.75 16.5697 19.7342 16.5286 19.7153 16.5019C19.6978 16.4771 19.6776 16.4634 19.6537 16.4564C18.9397 16.2482 17.7517 16.0019 16.6233 16.19C15.5373 16.371 14.6133 16.7417 13.6381 17.1329C13.3536 17.247 13.0648 17.3629 12.7664 17.4763C11.4753 17.9669 10.074 18.3729 8.3767 18.09C6.85292 17.836 5.69712 17.5592 5.00591 17.3751C4.24238 17.1717 3.75 16.4793 3.75 15.7229V8.4502C3.75 8.31212 3.63807 8.2002 3.5 8.2002H2.5ZM0.75 8.4502C0.75 7.4837 1.5335 6.7002 2.5 6.7002H3.5C4.4665 6.7002 5.25 7.4837 5.25 8.4502V15.7229C5.25 15.8336 5.31759 15.9058 5.39205 15.9256C6.04309 16.0991 7.15209 16.3652 8.6233 16.6104C9.92602 16.8275 11.0246 16.5335 12.2336 16.0741C12.48 15.9805 12.7347 15.878 12.9981 15.772C13.994 15.3714 15.1134 14.9209 16.3767 14.7104C17.8351 14.4673 19.2806 14.7851 20.0737 15.0164C20.8177 15.2334 21.25 15.9191 21.25 16.6217V17.4346C21.25 18.5332 20.2641 19.3493 19.231 19.2701C18.8957 19.2444 18.5935 19.2248 18.345 19.2087C18.1885 19.1985 18.0534 19.1897 17.9447 19.1817C16.3551 19.0641 15.1509 19.5564 13.7886 20.1245L13.7488 20.1411C12.4095 20.6997 10.9119 21.3242 8.94468 21.1787C6.30852 20.9837 4.06952 20.4625 1.74882 19.3993C1.12503 19.1135 0.75 18.4901 0.75 17.8288V8.4502Z"
            fill="url(#paint1_linear_6134_50121)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5 8.2002C12.2574 8.2002 11.25 9.20755 11.25 10.4502C11.25 11.6928 12.2574 12.7002 13.5 12.7002C14.7426 12.7002 15.75 11.6928 15.75 10.4502C15.75 9.20755 14.7426 8.2002 13.5 8.2002ZM9.75 10.4502C9.75 8.37913 11.4289 6.7002 13.5 6.7002C15.5711 6.7002 17.25 8.37913 17.25 10.4502C17.25 12.5213 15.5711 14.2002 13.5 14.2002C11.4289 14.2002 9.75 12.5213 9.75 10.4502Z"
            fill="#FDD671"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5 8.2002C12.2574 8.2002 11.25 9.20755 11.25 10.4502C11.25 11.6928 12.2574 12.7002 13.5 12.7002C14.7426 12.7002 15.75 11.6928 15.75 10.4502C15.75 9.20755 14.7426 8.2002 13.5 8.2002ZM9.75 10.4502C9.75 8.37913 11.4289 6.7002 13.5 6.7002C15.5711 6.7002 17.25 8.37913 17.25 10.4502C17.25 12.5213 15.5711 14.2002 13.5 14.2002C11.4289 14.2002 9.75 12.5213 9.75 10.4502Z"
            fill="url(#paint2_linear_6134_50121)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.75 11.4502C6.75 11.036 7.08579 10.7002 7.5 10.7002H7.51C7.92421 10.7002 8.26 11.036 8.26 11.4502C8.26 11.8644 7.92421 12.2002 7.51 12.2002H7.5C7.08579 12.2002 6.75 11.8644 6.75 11.4502Z"
            fill="#FDD671"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.75 11.4502C6.75 11.036 7.08579 10.7002 7.5 10.7002H7.51C7.92421 10.7002 8.26 11.036 8.26 11.4502C8.26 11.8644 7.92421 12.2002 7.51 12.2002H7.5C7.08579 12.2002 6.75 11.8644 6.75 11.4502Z"
            fill="url(#paint3_linear_6134_50121)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.75 9.4502C18.75 9.03598 19.0858 8.7002 19.5 8.7002H19.51C19.9242 8.7002 20.26 9.03598 20.26 9.4502C20.26 9.86441 19.9242 10.2002 19.51 10.2002H19.5C19.0858 10.2002 18.75 9.86441 18.75 9.4502Z"
            fill="#FDD671"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.75 9.4502C18.75 9.03598 19.0858 8.7002 19.5 8.7002H19.51C19.9242 8.7002 20.26 9.03598 20.26 9.4502C20.26 9.86441 19.9242 10.2002 19.51 10.2002H19.5C19.0858 10.2002 18.75 9.86441 18.75 9.4502Z"
            fill="url(#paint4_linear_6134_50121)"
          />
          <path
            d="M24 6C24 9.31371 21.3137 12 18 12C14.6863 12 12 9.31371 12 6C12 2.68629 14.6863 0 18 0C21.3137 0 24 2.68629 24 6Z"
            fill="#50555C"
          />
          <path
            d="M15.5 5.75H21"
            stroke="#FCFCFE"
            strokeWidth={2}
            strokeLinecap="round"
          />
          <path
            d="M18.25 8.5V3"
            stroke="#FCFCFE"
            strokeWidth={2}
            strokeLinecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6134_50121"
              x1="13.5"
              y1="2.70019"
              x2="22.8384"
              y2="16.7983"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#6A726C" />
              <stop offset={1} stopColor="#979797" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_6134_50121"
              x1={11}
              y1="6.70019"
              x2="19.3006"
              y2="20.7828"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#6A726C" />
              <stop offset={1} stopColor="#979797" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_6134_50121"
              x1="13.5"
              y1="6.7002"
              x2="18.327"
              y2="12.4925"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#50555C" />
              <stop offset={1} stopColor="#898A8D" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_6134_50121"
              x1="7.505"
              y1="10.7002"
              x2="8.46922"
              y2="11.865"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#50555C" />
              <stop offset={1} stopColor="#979797" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_6134_50121"
              x1="19.505"
              y1="8.7002"
              x2="20.4692"
              y2="9.86497"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset={1} stopColor="#CD9F00" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 -right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <ReceiveArrowIcon />
      </div>
    </div>
  );
};

export default CreditMoneyIcon;
