import React from "react";
import SmallButton from "../../Buttons/SmallButton";
import { EmptyNotificationIcon } from "../../Icons";

const EmptyNotification = ({ getUserNotification }) => {
  return (
    <div className="flex flex-col items-center justify-center max-w-[360px] min-h-[200px] px-4 py-6">
      <EmptyNotificationIcon className="w-32 h-32 my-10" />
      <span className="text-lg sm:text-xl text-body-text font-bold mt-4 text-center">
        No new notifications
      </span>
      <span className="text-sm text-paragraph-text font-medium mt-2 text-center">
        You're all caught up! There are currently no new notifications to show.
      </span>
      <SmallButton onClick={getUserNotification} text="Refresh" />
    </div>
  );
};

export default EmptyNotification;
