import React from "react";

const EmptyTransactionIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        // width="32"
        // height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.715 1.77463C17.2075 2.16941 17.2867 2.88868 16.8919 3.38116L14.2519 6.67446C13.8571 7.16693 13.1378 7.24613 12.6454 6.85134C12.1529 6.45655 12.0737 5.73728 12.4685 5.24481L15.1085 1.95151C15.5033 1.45903 16.2225 1.37984 16.715 1.77463Z"
          className="fill-paragraph-text"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0954 4.8346C14.0198 4.61794 14.9881 4.48438 15.9962 4.48438C23.1741 4.48438 28.9924 10.3027 28.9924 17.4806C28.9924 24.6584 23.1741 30.4768 15.9962 30.4768C8.81836 30.4768 3 24.6584 3 17.4806C3 14.5449 4.01381 11.8679 5.6286 9.71487C6.00731 9.20993 6.72365 9.10759 7.2286 9.4863C7.73354 9.86501 7.83588 10.5814 7.45717 11.0863C6.11196 12.8799 5.28571 15.0829 5.28571 17.4806C5.28571 23.3961 10.0807 28.1911 15.9962 28.1911C21.9117 28.1911 26.7067 23.3961 26.7067 17.4806C26.7067 11.5651 21.9117 6.77009 15.9962 6.77009C15.191 6.77009 14.3993 6.87666 13.617 7.06C13.0025 7.20404 12.3875 6.82262 12.2435 6.20809C12.0995 5.59356 12.4809 4.97863 13.0954 4.8346Z"
          className="fill-paragraph-text"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 12C16.0523 12 16.5 12.4477 16.5 13V18H20.5C21.0523 18 21.5 18.4477 21.5 19C21.5 19.5523 21.0523 20 20.5 20H16.5C15.3954 20 14.5 19.1046 14.5 18V13C14.5 12.4477 14.9477 12 15.5 12Z"
          className="fill-paragraph-text"
        />
        <circle cx="16" cy="18.5" r="2" className="fill-paragraph-text" />
      </svg>
    </div>
  );
};

export default EmptyTransactionIcon;
