import React from "react";
import CommonButton from "../../Buttons/CommonButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RejectedSign } from "../../Icons";
import { GetThemeClass } from "../../../helper";

const KYCStatus = ({ status }) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const isDarkTheme = GetThemeClass("light", "dark") === "dark";

  const isSuccess = status === "success";
  const message = isSuccess
    ? {
        title: "KYC Successful!",
        text: "Thank you for completing the KYC. You can close this page now.",
        // colorClass: "text-highlighter-green",
        colorClass: "text-primary-item",
        iconSrc: isDarkTheme ? "assets/gifs/done-dark.gif" : "assets/gifs/done-light.gif",
        iconAlt: "done",
      }
    : {
        title: "KYC Failed",
        text: "Unfortunately, we couldn't verify your identity. Please try again later.",
        colorClass: "text-highlighter-red",
        IconComponent: RejectedSign,
      };

  return (
    <div className="flex flex-col items-center justify-center min-h-[80dvh] md:p-4 px-2">
      <div className="bg-primarycard-gradient flex flex-col items-center justify-center shadow-lg md:p-12 max-md:py-6 max-md:px-4  rounded-3xl max-w-lg w-full">
        {isSuccess ? (
          <img
            src={message.iconSrc}
            alt={message.iconAlt}
            className="w-36 h-36 my-4"
          />
        ) : (
          <message.IconComponent className="w-28 h-28 my-4" />
        )}
        <h1
          className={`text-3xl md:text-4xl font-bold mb-6 text-center ${message.colorClass}`}
        >
          {message.title}
        </h1>
        <p className="text-lg text-body-text md:text-xl text-center">
          {message.text}
        </p>
        {isSuccess && user?.token && (
          <div className="mt-6 flex justify-center w-full">
            <CommonButton onClick={() => navigate("/")}>
              Go to home screen
            </CommonButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default KYCStatus;
