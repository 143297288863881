const AddIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/Add">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6104 4C13.2415 4 13.7532 4.51167 13.7532 5.14286V10.8571H19.4675C20.0987 10.8571 20.6104 11.3688 20.6104 12C20.6104 12.6312 20.0987 13.1429 19.4675 13.1429H13.7532V18.8571C13.7532 19.4883 13.2415 20 12.6104 20C11.9792 20 11.4675 19.4883 11.4675 18.8571V13.1429H5.75321C5.12203 13.1429 4.61035 12.6312 4.61035 12C4.61035 11.3688 5.12203 10.8571 5.75321 10.8571H11.4675V5.14286C11.4675 4.51167 11.9792 4 12.6104 4Z"
            className="dark:fill-icon-btn fill-primary-item"
          />
        </g>
      </svg>
    </div>
  );
};

export default AddIcon;
