const ShowIcon = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="9"
        viewBox="0 0 18 9"
        fill="none"
      >
        <path
          d="M16.7165 6.89062C15.0823 3.45055 12.1798 1.38681 9.00647 1.38681H9.0098C5.83647 1.38681 2.93397 3.45055 1.2998 6.89062"
          className="stroke-body-text"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="8.9082" cy="6.21484" r="2" className="fill-body-text" />
      </svg>
    </div>
  );
};

export default ShowIcon;
