import React from "react";
import Skeleton from "../../Common/Skeleton";

const CryptoDepositAddressSkeleton = () => {
  return (
    <div className="p-6 bg-input-bg rounded-3xl shadow-box-shadow my-3">
      <div className="text-body-text flex flex-col items-center justify-center">
        <Skeleton className="rounded w-[250px] h-[250px]" />
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 space-y-2 my-10">
        {[1, 2, 3, 4].map((item) => (
          <span key={item} className="space-y-3">
            <Skeleton className="rounded-full w-[110px] h-[16px]" />
            <Skeleton className="rounded-full w-[210px] h-[10px]" />
          </span>
        ))}
      </div>
    </div>
  );
};

export default CryptoDepositAddressSkeleton;
