const UnblockCardIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 10.5H8.5"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6 8.39844H14.2C13.5373 8.39844 13 8.9357 13 9.59844V12.7984C13 13.4612 13.5373 13.9984 14.2 13.9984H21.6C22.2627 13.9984 22.8 13.4612 22.8 12.7984V9.59844C22.8 8.9357 22.2627 8.39844 21.6 8.39844Z"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4551 4.27345L15.9703 2.75826C16.4726 2.25594 17.1539 1.97374 17.8643 1.97374C18.5746 1.97374 19.2559 2.25594 19.7583 2.75826C20.2606 3.26058 20.5428 3.94187 20.5428 4.65225C20.5428 5.36264 20.2606 6.04393 19.7583 6.54625L18.2431 8.06145"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 15.5H8"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 17.03V17.11C21.7454 18.5 21.9285 17.8879 21.7454 18.5C21.2821 20.048 20.1041 20.5 17.56 20.5H6.44C2.89 20.5 2 19.62 2 16.11V9.89C2 7.38 2.89 6.5 6.44 6.5H8.5"
          className="stroke-primary-item dark:stroke-icon-btn"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default UnblockCardIcon;