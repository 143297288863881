import React from "react";

const CommonButton = ({ children, ...props }) => {
  return (
    <button
      className={
        "bg-[#70CC4A] text-button-text hover:bg-button-hover-bg w-full h-auto md:max-w-96 md:h-12 px-4 py-3 rounded-full font-medium leading-normal tracking-tight disabled:hover:bg-[#70CC4A] disabled:hover:bg-opacity-50 disabled:bg-opacity-50 disabled:cursor-not-allowed"
      }
      {...props}
    >
      {children}
    </button>
  );
};

export default CommonButton;
