import React from "react";
import CommonButton from "../../Buttons/CommonButton";
import AddAccountModal from "../../Modals/AddAccount/AddAccountModal";

const EmptyCrypto = () => {
  return (
    <>
      <div className="space-y-6 border items-center border-dropdown-popup-bg w-full mt-3 bg-primarycard-gradient px-3 py-6 shadow-box-shadow flex flex-col rounded-3xl">
        <div className="flex items-center justify-center">
          <p className="text-body-text font-semibold text-xl md:text-2xl">
            It's time for your first crypto!
          </p>
        </div>
        <div className="bg-crypto-empty-bg md:block relative rounded-2xl md:w-[350px] h-[330px] w-[250px] md:h-[460px] bg-cover bg-center bg-no-repeat"></div>
         {/* Button with Full Width */}
         <div className="flex flex-col justify-between space-y-4 w-full md:w-[400px] mx-auto">
          <AddAccountModal crypto={true}>
            <CommonButton>Add Crypto</CommonButton>
          </AddAccountModal>
        </div>
      </div>
    </>
  );
};

export default EmptyCrypto;
