import React from "react";

const EmptyNotificationIcon = ({ className }) => {
  return (
    <div className={className}>
      <div className="hidden dark:block">
        <svg
          width={150}
          height={142}
          viewBox="0 0 150 142"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.5873 7.00916C77.2552 6.87211 76.8993 6.80188 76.5401 6.8025L76.5371 6.80251C75.8118 6.80313 75.1163 7.09155 74.6035 7.60444C74.0909 8.11703 73.8025 8.81197 73.8015 9.53686V12.9789H66.999L66.999 9.53224C67.0012 7.00481 68.0062 4.58152 69.7934 2.79435C71.5802 1.00751 74.0029 0.00256106 76.5299 1.10124e-05L76.5313 9.63073e-06C77.7838 -0.00177054 79.0245 0.243255 80.1823 0.721095C81.3411 1.19931 82.3942 1.90133 83.2814 2.78696C84.1685 3.67261 84.8724 4.72449 85.3526 5.88242C85.8328 7.04035 86.08 8.28161 86.08 9.53519V12.8515H79.2775V9.53518C79.2775 9.17593 79.2066 8.82019 79.069 8.48834C78.9314 8.15648 78.7297 7.85502 78.4754 7.60121C78.2212 7.3474 77.9194 7.14621 77.5873 7.00916ZM76.5313 9.63073e-06L76.5299 1.10124e-05L76.5313 9.63073e-06Z"
            fill="url(#paint0_linear_5030_47340)"
          />
          <path
            d="M76.9537 124.138C91.0524 124.138 102.482 112.709 102.482 98.61C102.482 84.5113 91.0524 73.082 76.9537 73.082C62.855 73.082 51.4258 84.5113 51.4258 98.61C51.4258 112.709 62.855 124.138 76.9537 124.138Z"
            fill="url(#paint1_linear_5030_47340)"
          />
          <path
            d="M117.233 70.4657V50.8806C117.233 40.198 112.989 29.9528 105.435 22.399C97.8814 14.8452 87.6362 10.6016 76.9536 10.6016C66.2709 10.6016 56.0257 14.8452 48.472 22.399C40.9182 29.9528 36.6745 40.198 36.6745 50.8806V70.4657C36.6749 74.9652 35.4021 79.373 33.003 83.1795C30.604 86.9861 27.1767 90.0361 23.1172 91.9769C22.1842 92.4212 21.3966 93.1214 20.8462 93.996C20.2957 94.8706 20.0049 95.8835 20.0078 96.9169V97.023C20.0071 97.3963 20.0801 97.766 20.2224 98.111C20.3648 98.456 20.5737 98.7696 20.8374 99.0338C21.1011 99.298 21.4143 99.5075 21.759 99.6505C22.1038 99.7935 22.4734 99.8671 22.8466 99.8671H131.055C131.428 99.8671 131.798 99.7935 132.143 99.6505C132.488 99.5075 132.801 99.298 133.064 99.0338C133.328 98.7696 133.537 98.456 133.679 98.111C133.822 97.766 133.895 97.3963 133.894 97.023V96.9169C133.894 95.8868 133.603 94.8776 133.054 94.0062C132.504 93.1348 131.719 92.4367 130.79 91.9928C126.728 90.0508 123.299 86.9984 120.9 83.1887C118.501 79.3791 117.229 74.9679 117.233 70.4657Z"
            fill="url(#paint2_linear_5030_47340)"
          />
          <path
            d="M149.277 45.3465C149.307 45.2921 149.325 45.232 149.33 45.1701C149.336 45.1082 149.328 45.0459 149.307 44.9873C149.287 44.9286 149.254 44.875 149.211 44.8299C149.168 44.7848 149.117 44.7493 149.059 44.7256C147.382 44.0571 141.45 42.0726 138.208 46.0734C136.039 48.7029 132.973 50.4362 129.601 50.9392C129.517 50.9505 129.437 50.9873 129.374 51.0446C129.31 51.1019 129.265 51.1771 129.245 51.2603C129.225 51.3435 129.231 51.4308 129.261 51.5108C129.292 51.5908 129.345 51.6597 129.416 51.7086C132.281 53.7408 141.747 58.9196 149.277 45.3465Z"
            fill="url(#paint3_linear_5030_47340)"
          />
          <path
            d="M23.6198 73.405C23.6437 73.3613 23.6582 73.313 23.6622 73.2633C23.6663 73.2136 23.6598 73.1636 23.6433 73.1166C23.6268 73.0696 23.6006 73.0265 23.5664 72.9903C23.5322 72.954 23.4907 72.9254 23.4447 72.9062C22.1022 72.3756 17.332 70.7838 14.7267 73.9675C12.9882 76.077 10.5315 77.4697 7.82864 77.8781C7.76078 77.8872 7.69725 77.9166 7.64639 77.9624C7.59554 78.0083 7.55973 78.0684 7.54366 78.135C7.52759 78.2015 7.53202 78.2714 7.55637 78.3354C7.58071 78.3994 7.62383 78.4546 7.68007 78.4936C9.96703 80.1491 17.5708 84.3092 23.6198 73.405Z"
            fill="url(#paint4_linear_5030_47340)"
          />
          <path
            d="M136.224 67.3864C136.204 67.35 136.192 67.3097 136.188 67.2682C136.185 67.2266 136.191 67.1849 136.205 67.1457C136.219 67.1065 136.241 67.0707 136.27 67.0408C136.299 67.011 136.334 66.9877 136.373 66.9725C137.498 66.5215 141.483 65.1896 143.658 67.8745C145.117 69.6408 147.177 70.8051 149.442 71.1431C149.502 71.1486 149.559 71.173 149.604 71.2127C149.65 71.2524 149.682 71.3054 149.695 71.3642C149.709 71.4231 149.703 71.4847 149.68 71.5402C149.656 71.5958 149.616 71.6425 149.564 71.6737C147.643 73.048 141.281 76.5236 136.224 67.3864Z"
            fill="url(#paint5_linear_5030_47340)"
          />
          <path
            d="M0.0592421 50.1645C0.0264923 50.1038 0.00681559 50.037 0.00148021 49.9683C-0.00385517 49.8995 0.00526914 49.8305 0.0282651 49.7655C0.051261 49.7005 0.0876183 49.641 0.134994 49.5909C0.182369 49.5409 0.239712 49.5013 0.303326 49.4747C2.16048 48.7371 8.73482 46.5404 12.3271 50.9657C14.7334 53.8834 18.1368 55.8045 21.8782 56.3568C21.9717 56.3706 22.059 56.4122 22.1287 56.4761C22.1983 56.5401 22.2472 56.6235 22.2689 56.7155C22.2906 56.8076 22.2842 56.904 22.2505 56.9924C22.2168 57.0807 22.1573 57.1569 22.0798 57.2111C18.8908 59.4609 8.40053 65.2022 0.0592421 50.1645Z"
            fill="url(#paint6_linear_5030_47340)"
          />
          <path
            d="M21.9773 31.3433L29.9749 33.5908L24.0298 39.3932L21.9773 31.3433Z"
            fill="url(#paint7_linear_5030_47340)"
          />
          <path
            d="M133.79 4.8125L136.029 14.0852L145.301 16.3235L136.029 18.5619L133.79 27.8346L131.552 18.5619L122.279 16.3235L131.552 14.0852L133.79 4.8125Z"
            fill="url(#paint8_linear_5030_47340)"
          />
          <path
            d="M51.4983 118.979L53.7367 128.251L63.0094 130.49L53.7367 132.728L51.4983 142.001L49.26 132.728L39.9873 130.49L49.26 128.251L51.4983 118.979Z"
            fill="url(#paint9_linear_5030_47340)"
          />
          <path
            d="M35.6913 117.699C35.6913 119.553 34.1882 121.057 32.3339 121.057C30.4797 121.057 28.9766 119.553 28.9766 117.699C28.9766 115.845 30.4797 114.342 32.3339 114.342C34.1882 114.342 35.6913 115.845 35.6913 117.699Z"
            fill="url(#paint10_linear_5030_47340)"
          />
          <circle
            cx="36.3906"
            cy="13.9648"
            r="3.35738"
            fill="url(#paint11_linear_5030_47340)"
          />
          <circle
            cx="141.284"
            cy="112.484"
            r="3.35738"
            fill="url(#paint12_linear_5030_47340)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5030_47340"
              x1="79.1821"
              y1="11.9237"
              x2="75.9965"
              y2="1.13146"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5030_47340"
              x1="84.0247"
              y1="119.987"
              x2="67.1704"
              y2="81.1479"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_5030_47340"
              x1="92.7234"
              y1="92.6095"
              x2="68.1228"
              y2="20.2839"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_5030_47340"
              x1="142.066"
              y1="53.5143"
              x2="139.927"
              y2="44.2953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_5030_47340"
              x1="17.8326"
              y1="79.9642"
              x2="16.1144"
              y2="72.5631"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_5030_47340"
              x1="144.817"
              y1="72.8896"
              x2="143.375"
              y2="66.6837"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_5030_47340"
              x1="14.2266"
              y1="59.2121"
              x2="11.8589"
              y2="48.9997"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_5030_47340"
              x1="29.0834"
              y1="36.7222"
              x2="21.7204"
              y2="33.7117"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_5030_47340"
              x1="136.979"
              y1="25.9628"
              x2="129.379"
              y2="8.44956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_5030_47340"
              x1="54.6868"
              y1="140.129"
              x2="47.0869"
              y2="122.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_5030_47340"
              x1="33.2639"
              y1="120.511"
              x2="31.0473"
              y2="115.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_5030_47340"
              x1="37.3205"
              y1="16.7763"
              x2="35.1039"
              y2="11.6682"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_5030_47340"
              x1="142.214"
              y1="115.296"
              x2="139.997"
              y2="110.188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="block dark:hidden">
        <svg
          width={150}
          height={142}
          viewBox="0 0 150 142"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.5877 7.00916C77.2556 6.87211 76.8997 6.80188 76.5405 6.8025L76.5375 6.80251C75.8122 6.80313 75.1167 7.09155 74.6038 7.60444C74.0913 8.11703 73.8029 8.81197 73.8019 9.53686V12.9789H66.9994L66.9994 9.53224C67.0016 7.00481 68.0066 4.58152 69.7938 2.79435C71.5806 1.00751 74.0033 0.00256106 76.5303 1.10124e-05L76.5317 9.63073e-06C77.7842 -0.00177054 79.0249 0.243255 80.1827 0.721095C81.3415 1.19931 82.3946 1.90133 83.2818 2.78696C84.1689 3.67261 84.8728 4.72449 85.353 5.88242C85.8332 7.04035 86.0804 8.28161 86.0804 9.53519V12.8515H79.2779V9.53518C79.2779 9.17593 79.207 8.82019 79.0694 8.48834C78.9318 8.15648 78.7301 7.85502 78.4758 7.60121C78.2216 7.3474 77.9198 7.14621 77.5877 7.00916ZM76.5317 9.63073e-06L76.5303 1.10124e-05L76.5317 9.63073e-06Z"
            fill="url(#paint0_linear_1355_52200)"
          />
          <path
            d="M76.9538 124.138C91.0525 124.138 102.482 112.709 102.482 98.61C102.482 84.5113 91.0525 73.082 76.9538 73.082C62.8551 73.082 51.4259 84.5113 51.4259 98.61C51.4259 112.709 62.8551 124.138 76.9538 124.138Z"
            fill="url(#paint1_linear_1355_52200)"
          />
          <path
            d="M117.233 70.4657V50.8806C117.233 40.198 112.989 29.9528 105.435 22.399C97.8815 14.8452 87.6364 10.6016 76.9537 10.6016C66.2711 10.6016 56.0259 14.8452 48.4721 22.399C40.9183 29.9528 36.6747 40.198 36.6747 50.8806V70.4657C36.6751 74.9652 35.4022 79.373 33.0032 83.1795C30.6041 86.9861 27.1768 90.0361 23.1174 91.9769C22.1844 92.4212 21.3968 93.1214 20.8463 93.996C20.2958 94.8706 20.0051 95.8835 20.008 96.9169V97.023C20.0073 97.3963 20.0802 97.766 20.2226 98.111C20.3649 98.456 20.5739 98.7696 20.8376 99.0338C21.1013 99.298 21.4144 99.5075 21.7592 99.6505C22.104 99.7935 22.4736 99.8671 22.8468 99.8671H131.055C131.429 99.8671 131.798 99.7935 132.143 99.6505C132.488 99.5075 132.801 99.298 133.065 99.0338C133.328 98.7696 133.537 98.456 133.68 98.111C133.822 97.766 133.895 97.3963 133.894 97.023V96.9169C133.895 95.8868 133.603 94.8776 133.054 94.0062C132.504 93.1348 131.72 92.4367 130.79 91.9928C126.728 90.0508 123.299 86.9984 120.9 83.1887C118.501 79.3791 117.229 74.9679 117.233 70.4657Z"
            fill="url(#paint2_linear_1355_52200)"
          />
          <path
            d="M149.277 45.3465C149.307 45.2921 149.325 45.232 149.331 45.1701C149.336 45.1082 149.328 45.0459 149.307 44.9873C149.287 44.9286 149.254 44.875 149.211 44.8299C149.169 44.7848 149.117 44.7493 149.059 44.7256C147.383 44.0571 141.45 42.0726 138.208 46.0734C136.039 48.7029 132.973 50.4362 129.602 50.9392C129.517 50.9505 129.437 50.9873 129.374 51.0446C129.31 51.1019 129.266 51.1771 129.245 51.2603C129.225 51.3435 129.231 51.4308 129.261 51.5108C129.292 51.5908 129.346 51.6597 129.416 51.7086C132.281 53.7408 141.748 58.9196 149.277 45.3465Z"
            fill="url(#paint3_linear_1355_52200)"
          />
          <path
            d="M23.6194 73.405C23.6433 73.3613 23.6578 73.313 23.6618 73.2633C23.6659 73.2136 23.6595 73.1636 23.643 73.1166C23.6264 73.0696 23.6002 73.0265 23.566 72.9903C23.5318 72.954 23.4903 72.9254 23.4443 72.9062C22.1018 72.3756 17.3316 70.7838 14.7263 73.9675C12.9878 76.077 10.5311 77.4697 7.82826 77.8781C7.7604 77.8872 7.69687 77.9166 7.64601 77.9624C7.59516 78.0083 7.55935 78.0684 7.54328 78.135C7.52721 78.2015 7.53164 78.2714 7.55599 78.3354C7.58033 78.3994 7.62345 78.4546 7.67969 78.4936C9.96665 80.1491 17.5704 84.3092 23.6194 73.405Z"
            fill="url(#paint4_linear_1355_52200)"
          />
          <path
            d="M136.225 67.3864C136.205 67.35 136.192 67.3097 136.189 67.2682C136.185 67.2266 136.191 67.1849 136.205 67.1457C136.219 67.1065 136.241 67.0707 136.27 67.0408C136.3 67.011 136.335 66.9877 136.373 66.9725C137.498 66.5215 141.483 65.1896 143.659 67.8745C145.117 69.6408 147.177 70.8051 149.442 71.1431C149.503 71.1486 149.559 71.173 149.605 71.2127C149.65 71.2524 149.682 71.3054 149.696 71.3642C149.709 71.4231 149.704 71.4847 149.68 71.5402C149.657 71.5958 149.616 71.6425 149.565 71.6737C147.644 73.048 141.282 76.5236 136.225 67.3864Z"
            fill="url(#paint5_linear_1355_52200)"
          />
          <path
            d="M0.0592421 50.1645C0.0264923 50.1038 0.00681559 50.037 0.00148021 49.9683C-0.00385517 49.8995 0.00526914 49.8305 0.0282651 49.7655C0.051261 49.7005 0.0876183 49.641 0.134994 49.5909C0.182369 49.5409 0.239712 49.5013 0.303326 49.4747C2.16048 48.7371 8.73482 46.5404 12.3271 50.9657C14.7334 53.8834 18.1368 55.8045 21.8782 56.3568C21.9717 56.3706 22.059 56.4122 22.1287 56.4761C22.1983 56.5401 22.2472 56.6235 22.2689 56.7155C22.2906 56.8076 22.2842 56.904 22.2505 56.9924C22.2168 57.0807 22.1573 57.1569 22.0798 57.2111C18.8908 59.4609 8.40053 65.2022 0.0592421 50.1645Z"
            fill="url(#paint6_linear_1355_52200)"
          />
          <path
            d="M21.9772 31.3433L29.9748 33.5908L24.0296 39.3932L21.9772 31.3433Z"
            fill="url(#paint7_linear_1355_52200)"
          />
          <path
            d="M133.79 4.8125L136.029 14.0852L145.301 16.3235L136.029 18.5619L133.79 27.8346L131.552 18.5619L122.279 16.3235L131.552 14.0852L133.79 4.8125Z"
            fill="url(#paint8_linear_1355_52200)"
          />
          <path
            d="M51.4981 118.979L53.7365 128.251L63.0092 130.49L53.7365 132.728L51.4981 142.001L49.2598 132.728L39.9871 130.49L49.2598 128.251L51.4981 118.979Z"
            fill="url(#paint9_linear_1355_52200)"
          />
          <path
            d="M35.6909 117.699C35.6909 119.553 34.1878 121.057 32.3335 121.057C30.4793 121.057 28.9762 119.553 28.9762 117.699C28.9762 115.845 30.4793 114.342 32.3335 114.342C34.1878 114.342 35.6909 115.845 35.6909 117.699Z"
            fill="url(#paint10_linear_1355_52200)"
          />
          <circle
            cx="36.3902"
            cy="13.9648"
            r="3.35738"
            fill="url(#paint11_linear_1355_52200)"
          />
          <circle
            cx="141.284"
            cy="112.484"
            r="3.35738"
            fill="url(#paint12_linear_1355_52200)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1355_52200"
              x1="79.1825"
              y1="11.9237"
              x2="75.9969"
              y2="1.13146"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1355_52200"
              x1="84.0248"
              y1="119.987"
              x2="67.1705"
              y2="81.1479"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1355_52200"
              x1="92.7236"
              y1="92.6095"
              x2="68.123"
              y2="20.2839"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1355_52200"
              x1="142.066"
              y1="53.5143"
              x2="139.927"
              y2="44.2953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1355_52200"
              x1="17.8322"
              y1="79.9642"
              x2="16.114"
              y2="72.5631"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1355_52200"
              x1="144.817"
              y1="72.8896"
              x2="143.375"
              y2="66.6837"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_1355_52200"
              x1="14.2266"
              y1="59.2121"
              x2="11.8589"
              y2="48.9997"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_1355_52200"
              x1="29.0832"
              y1="36.7222"
              x2="21.7202"
              y2="33.7117"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_1355_52200"
              x1="136.979"
              y1="25.9628"
              x2="129.379"
              y2="8.44956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_1355_52200"
              x1="54.6865"
              y1="140.129"
              x2="47.0866"
              y2="122.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_1355_52200"
              x1="33.2635"
              y1="120.511"
              x2="31.0469"
              y2="115.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_1355_52200"
              x1="37.3201"
              y1="16.7763"
              x2="35.1035"
              y2="11.6682"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_1355_52200"
              x1="142.214"
              y1="115.296"
              x2="139.997"
              y2="110.188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default EmptyNotificationIcon;
