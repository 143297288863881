import React from "react";
import Button from "../../Button";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../Buttons/CommonButton";
import { GetThemeClass } from "../../../helper";

const TransactionCompleted = ({
  title,
  description,
  handleViewTransaction,
  onClose,
  navigateUrl,
}) => {
  const navigate = useNavigate();

  const handleDoneClick = () => {
    if (navigateUrl) {
      navigate(navigateUrl);
    } else {
      onClose();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center py-5">
      <div className="flex flex-col justify-center items-center">
        {GetThemeClass("light", "dark") === "dark" ? (
          <img
            src="assets/gifs/done-dark.gif"
            alt="done"
            className="w-36 h-36"
          />
        ) : (
          <img
            src="assets/gifs/done-light.gif"
            alt="done"
            className="w-36 h-36"
          />
        )}
        <h2 className="text-center text-body-text text-2xl mt-6 font-medium leading-loose tracking-tight">
          {title}
        </h2>
        <h3 className="text-center text-paragraph-text text-sm font-normal leading-tight tracking-tight">
          {description}
        </h3>
      </div>

      <div className="w-full space-y-4 mt-[72px]">
        <Button
          handleClick={handleViewTransaction}
          className="w-full rounded-full bg-secondary-icon-bg text-primary-item font-medium text-sm p-3"
          title={"View Tranasction"}
        />

        <CommonButton onClick={handleDoneClick}>Done</CommonButton>
      </div>
    </div>
  );
};

export default TransactionCompleted;
