const BellIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 60">
          <g id="Group 12475">
            <path
              id="Rectangle 1140"
              d="M5.99975 8.69947C5.99975 5.41367 8.66342 2.75 11.9492 2.75C15.235 2.75 17.8987 5.41367 17.8987 8.69947V13.1036L19.4492 17.2754H4.44922L5.99975 13.1036V8.69947Z"
              className="dark:stroke-icon-btn stroke-primary-item"
              strokeWidth="1.8"
            />
            <path
              id="Rectangle 1141"
              d="M14.9947 17.2754V17.4927C14.9947 19.1742 13.6315 20.5374 11.95 20.5374C10.2684 20.5374 8.90527 19.1742 8.90527 17.4927V17.2754"
              className="dark:stroke-icon-btn stroke-primary-item"
              strokeWidth="1.8"
            />
            <path
              id="Ellipse 4912"
              d="M14.5 2.5C14.5 3.05228 13.3807 3 12 3C10.6193 3 9.5 3.05228 9.5 2.5C9.5 1.94772 10.6193 0.5 12 0.5C13.3807 0.5 14.5 1.94772 14.5 2.5Z"
              className="dark:stroke-icon-btn stroke-primary-item"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default BellIcon;