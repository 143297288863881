import { Outlet } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import { useModules } from "../../hooks";

const CardRoutes = () => {
  const isVistaMoney = useModules("VistaMoney");

  return (
    <>
      {isVistaMoney ? (
        <>
          <Outlet />
        </>
      ) : (
        <ErrorPage description="Sorry, the page you are looking for could not be found." />
      )}
    </>
  );
};

export default CardRoutes;
