import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { DownArrowIcon } from "../Icons";

const ProfileAccordion = ({
  title,
  placeholder,
  startIcon,
  icon = true,
  children,
  openModal,
  setModalOpen,
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div className="w-full relative rounded-3xl  dark:bg-input-bg border border-divider-border shadow-sm transition-all duration-300 ease-in-out">
          <Disclosure.Button
            className={`flex w-full justify-between items-center px-3 md:px-6 py-4 cursor-pointer
              ${
                open
                  ? "bg-option-list-hover rounded-t-3xl"
                  : "hover:bg-option-list-hover rounded-3xl"
              }
               focus:outline-none`}
            onClick={(e) => {
              if (openModal) {
                e.preventDefault();
                setModalOpen(true);
              }
            }}
          >
            <div className="flex items-center whitespace-nowrap">
              <div className="inline-flex bg-secondary-icon-bg p-3 rounded-full justify-center items-center hover:bg-icon-btn-hover dark:hover:bg-active-state-bg">
                {startIcon}
              </div>
              <div className="flex px-3 flex-col items-start justify-start space-y-1">
                <h3 className="text-sm font-medium text-body-text">{title}</h3>
                <span className="text-sm font-normal text-paragraph-text">
                  {placeholder}
                </span>
              </div>
            </div>
            {icon && (
              <div
                className={`flex items-center ${
                  open ? "transform rotate-180" : ""
                } transition-transform duration-300 ease-in-out`}
              >
                <DownArrowIcon />
              </div>
            )}
          </Disclosure.Button>
          {!openModal && (
            <Transition
              enter="duration-200 ease-out"
              enterFrom="opacity-0 -translate-y-6"
              enterTo="opacity-100 translate-y-0"
              leave="duration-300 ease-out"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-6"
            >
              <Disclosure.Panel className="px-6 py-5">
                {children}
              </Disclosure.Panel>
            </Transition>
          )}
        </div>
      )}
    </Disclosure>
  );
};

export default ProfileAccordion;
