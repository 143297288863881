import React from "react";
import Skeleton from "../../Common/Skeleton";

const AllTransactionsTableSkeleton = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="">
        <Skeleton className="flex flex-col items-center space-y-3">
          <div className="md:hidden block">
            <div className="flex items-center justify-center space-x-20">
              {[1, 2, 3].map((item) => (
                <Skeleton key={item} className="rounded-full h-5 w-12 mt-5" />
              ))}
            </div>
          </div>

          <div className="hidden md:block">
            <div className="flex items-center justify-center space-x-16">
              {[1, 2, 3, 4].map((item) => (
                <Skeleton key={item} className="rounded-full h-5 w-20" />
              ))}
            </div>
          </div>

          <Skeleton className="rounded-full h-1 w-full" />
        </Skeleton>
      </div>

      <div className="pb-2 md:block hidden">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <Skeleton
            key={item}
            className="flex items-center justify-between w-full px-5 py-4 my-1 md:space-x-4"
          >
            <Skeleton className="rounded-full h-5 w-20 hidden md:block" />
            <div className="flex items-center space-x-2">
              <Skeleton className="rounded-full h-10 w-10" />
              <Skeleton className="rounded-full h-5 w-20" />
            </div>
            <Skeleton className="rounded-full h-5 w-20" />
            <Skeleton className="rounded-full h-5 w-20" />
          </Skeleton>
        ))}
      </div>
      <div className="pb-2 md:hidden block">
        {[1, 2, 3, 4, 5].map((item) => (
          <Skeleton
            key={item}
            className="flex items-center justify-between w-full px-5 py-4 my-1 rounded-3xl md:space-x-4"
          >
            <Skeleton className="rounded-full h-5 w-20 hidden md:block" />
            <div className="flex items-center space-x-2">
              <Skeleton className="rounded-full h-10 w-10" />
              <Skeleton className="rounded-full h-5 w-20" />
            </div>
            <Skeleton className="rounded-full h-5 w-20" />
            <Skeleton className="rounded-full h-5 w-20" />
          </Skeleton>
        ))}
      </div>
    </div>
  );
};

export default AllTransactionsTableSkeleton;
