const HelpIcon = ({ className, navbar }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 61">
          <g id="Group 61_2">
            <g id="Group 68">
              <path
                id="Subtract (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.66667C7.39763 3.66667 3.66667 7.39763 3.66667 12C3.66667 16.6024 7.39763 20.3333 12 20.3333C16.6024 20.3333 20.3333 16.6024 20.3333 12C20.3333 7.39763 16.6024 3.66667 12 3.66667ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                className={
                  navbar
                    ? "dark:fill-icon-btn fill-primary-item"
                    : "fill-primary-item"
                }
              />
              <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0906 8.89157C11.4299 8.69214 11.8289 8.61924 12.2168 8.68578C12.6048 8.75232 12.9566 8.95401 13.2101 9.25512C13.4636 9.55624 13.6023 9.93734 13.6017 10.3309L13.6017 10.3322C13.6017 10.7231 13.2975 11.1307 12.7228 11.5138C12.4608 11.6885 12.1929 11.823 11.9872 11.9144C11.8856 11.9596 11.802 11.993 11.7458 12.0144C11.7178 12.0251 11.6968 12.0327 11.684 12.0372L11.6707 12.0419C11.3309 12.1556 11.1017 12.4738 11.1017 12.8322V14.0822C11.1017 14.5424 11.4748 14.9155 11.9351 14.9155C12.3953 14.9155 12.7684 14.5424 12.7684 14.0822V13.3901C13.0177 13.2744 13.3302 13.1119 13.6473 12.9006C14.3225 12.4504 15.268 11.6082 15.2684 10.3329C15.2694 9.54591 14.992 8.78389 14.4851 8.18179C13.9782 7.57956 13.2745 7.17619 12.4986 7.0431C11.7227 6.91002 10.9248 7.05582 10.2461 7.45468C9.56745 7.85354 9.05184 8.47972 8.79061 9.22231C8.63789 9.65647 8.86603 10.1322 9.30019 10.285C9.73435 10.4377 10.2101 10.2095 10.3628 9.77539C10.4934 9.40409 10.7513 9.091 11.0906 8.89157ZM12.0005 15.3328C11.5403 15.3328 11.1672 15.7059 11.1672 16.1661C11.1672 16.6264 11.5403 16.9995 12.0005 16.9995H12.0089C12.4691 16.9995 12.8422 16.6264 12.8422 16.1661C12.8422 15.7059 12.4691 15.3328 12.0089 15.3328H12.0005Z"
                className={
                  navbar
                    ? "dark:fill-icon-btn fill-primary-item"
                    : "fill-primary-item"
                }
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default HelpIcon;
