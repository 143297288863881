import React from "react";
import { AddIcon } from "../../Icons";

const CardButton = ({ title, subtitle }) => {
  return (
    <div className="flex items-center md:p-6 p-4 bg-secondary-icon-bg hover:bg-option-list-hover rounded-3xl mt-4 cursor-pointer">
      <div className="border-[3px] p-2 rounded-full border-primary-item">
        <AddIcon />
      </div>
      <div className="pl-3">
        <h3 className="text-primary-item text-sm font-medium leading-tight tracking-tight">
          {title}
        </h3>
        <p className="text-paragraph-text text-sm font-normal leading-tight tracking-tight">
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default CardButton;
