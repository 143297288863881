import React from "react";

const CryptoDepositIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 9.25H2C1.59 9.25 1.25 8.91 1.25 8.5C1.25 8.09 1.59 7.75 2 7.75H14.5C14.91 7.75 15.25 8.09 15.25 8.5C15.25 8.91 14.91 9.25 14.5 9.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M17.56 21.25H6.44C2.46 21.25 1.25 20.05 1.25 16.11V7.89C1.25 3.95 2.46 2.75 6.44 2.75H14.5C14.91 2.75 15.25 3.09 15.25 3.5C15.25 3.91 14.91 4.25 14.5 4.25H6.44C3.3 4.25 2.75 4.79 2.75 7.89V16.1C2.75 19.2 3.3 19.74 6.44 19.74H17.55C20.69 19.74 21.24 19.2 21.24 16.1V14.02C21.24 13.61 21.58 13.27 21.99 13.27C22.4 13.27 22.74 13.61 22.74 14.02V16.1C22.75 20.05 21.54 21.25 17.56 21.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M20 10.25C19.9 10.25 19.81 10.23 19.71 10.19C19.43 10.07 19.25 9.8 19.25 9.5V3.5C19.25 3.09 19.59 2.75 20 2.75C20.41 2.75 20.75 3.09 20.75 3.5V7.69L21.47 6.97C21.76 6.68 22.24 6.68 22.53 6.97C22.82 7.26 22.82 7.74 22.53 8.03L20.53 10.03C20.39 10.17 20.2 10.25 20 10.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M19.9995 10.25C19.8095 10.25 19.6195 10.18 19.4695 10.03L17.4695 8.03C17.1795 7.74 17.1795 7.26 17.4695 6.97C17.7595 6.68 18.2395 6.68 18.5295 6.97L20.5295 8.97C20.8195 9.26 20.8195 9.74 20.5295 10.03C20.3795 10.18 20.1895 10.25 19.9995 10.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default CryptoDepositIcon;
