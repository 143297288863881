import React from "react";
import ReceiveArrowIcon from "../ReceiveArrowIcon";

const CryptoCreditIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-secondary-icon-bg rounded-full">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="#74736F"
          />
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="url(#paint0_linear_6145_50092)"
          />
          <path
            d="M14.98 14.98C14.73 11.81 12.19 9.27 9.02 9.02C8.85 9.01 8.67 9 8.5 9C4.91 9 2 11.91 2 15.5C2 19.09 4.91 22 8.5 22C12.09 22 15 19.09 15 15.5C15 15.33 14.99 15.15 14.98 14.98Z"
            stroke="url(#paint1_linear_6145_50092)"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.2 18.6C9.2 18.9314 8.93137 19.2 8.6 19.2C8.26863 19.2 8 18.9314 8 18.6V16.1969H5.6C5.26863 16.1969 5 15.9282 5 15.5969C5 15.2655 5.26863 14.9969 5.6 14.9969H8V12.6C8 12.2686 8.26863 12 8.6 12C8.93137 12 9.2 12.2686 9.2 12.6V14.9969H11.6C11.9314 14.9969 12.2 15.2655 12.2 15.5969C12.2 15.9282 11.9314 16.1969 11.6 16.1969H9.2V18.6Z"
            fill="#898A8D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.2 18.6C9.2 18.9314 8.93137 19.2 8.6 19.2C8.26863 19.2 8 18.9314 8 18.6V16.1969H5.6C5.26863 16.1969 5 15.9282 5 15.5969C5 15.2655 5.26863 14.9969 5.6 14.9969H8V12.6C8 12.2686 8.26863 12 8.6 12C8.93137 12 9.2 12.2686 9.2 12.6V14.9969H11.6C11.9314 14.9969 12.2 15.2655 12.2 15.5969C12.2 15.9282 11.9314 16.1969 11.6 16.1969H9.2V18.6Z"
            fill="url(#paint2_linear_6145_50092)"
          />
          <path
            d="M7.99961 16.197H8.14961C8.14961 16.1141 8.08245 16.047 7.99961 16.047V16.197ZM7.99961 14.997V15.147C8.08245 15.147 8.14961 15.0798 8.14961 14.997H7.99961ZM9.19961 14.997H9.04961C9.04961 15.0798 9.11677 15.147 9.19961 15.147V14.997ZM9.19961 16.197V16.047C9.11677 16.047 9.04961 16.1141 9.04961 16.197H9.19961ZM8.59961 19.3501C9.01382 19.3501 9.34961 19.0143 9.34961 18.6001H9.04961C9.04961 18.8486 8.84814 19.0501 8.59961 19.0501V19.3501ZM7.84961 18.6001C7.84961 19.0143 8.1854 19.3501 8.59961 19.3501V19.0501C8.35108 19.0501 8.14961 18.8486 8.14961 18.6001H7.84961ZM7.84961 16.197V18.6001H8.14961V16.197H7.84961ZM5.59961 16.347H7.99961V16.047H5.59961V16.347ZM4.84961 15.597C4.84961 16.0112 5.1854 16.347 5.59961 16.347V16.047C5.35108 16.047 5.14961 15.8455 5.14961 15.597H4.84961ZM5.59961 14.847C5.1854 14.847 4.84961 15.1828 4.84961 15.597H5.14961C5.14961 15.3484 5.35108 15.147 5.59961 15.147V14.847ZM7.99961 14.847H5.59961V15.147H7.99961V14.847ZM7.84961 12.6001V14.997H8.14961V12.6001H7.84961ZM8.59961 11.8501C8.1854 11.8501 7.84961 12.1859 7.84961 12.6001H8.14961C8.14961 12.3516 8.35108 12.1501 8.59961 12.1501V11.8501ZM9.34961 12.6001C9.34961 12.1859 9.01382 11.8501 8.59961 11.8501V12.1501C8.84814 12.1501 9.04961 12.3516 9.04961 12.6001H9.34961ZM9.34961 14.997V12.6001H9.04961V14.997H9.34961ZM11.5996 14.847H9.19961V15.147H11.5996V14.847ZM12.3496 15.597C12.3496 15.1828 12.0138 14.847 11.5996 14.847V15.147C11.8481 15.147 12.0496 15.3484 12.0496 15.597H12.3496ZM11.5996 16.347C12.0138 16.347 12.3496 16.0112 12.3496 15.597H12.0496C12.0496 15.8455 11.8481 16.047 11.5996 16.047V16.347ZM9.19961 16.347H11.5996V16.047H9.19961V16.347ZM9.34961 18.6001V16.197H9.04961V18.6001H9.34961Z"
            fill="#898A8D"
          />
          <path
            d="M7.99961 16.197H8.14961C8.14961 16.1141 8.08245 16.047 7.99961 16.047V16.197ZM7.99961 14.997V15.147C8.08245 15.147 8.14961 15.0798 8.14961 14.997H7.99961ZM9.19961 14.997H9.04961C9.04961 15.0798 9.11677 15.147 9.19961 15.147V14.997ZM9.19961 16.197V16.047C9.11677 16.047 9.04961 16.1141 9.04961 16.197H9.19961ZM8.59961 19.3501C9.01382 19.3501 9.34961 19.0143 9.34961 18.6001H9.04961C9.04961 18.8486 8.84814 19.0501 8.59961 19.0501V19.3501ZM7.84961 18.6001C7.84961 19.0143 8.1854 19.3501 8.59961 19.3501V19.0501C8.35108 19.0501 8.14961 18.8486 8.14961 18.6001H7.84961ZM7.84961 16.197V18.6001H8.14961V16.197H7.84961ZM5.59961 16.347H7.99961V16.047H5.59961V16.347ZM4.84961 15.597C4.84961 16.0112 5.1854 16.347 5.59961 16.347V16.047C5.35108 16.047 5.14961 15.8455 5.14961 15.597H4.84961ZM5.59961 14.847C5.1854 14.847 4.84961 15.1828 4.84961 15.597H5.14961C5.14961 15.3484 5.35108 15.147 5.59961 15.147V14.847ZM7.99961 14.847H5.59961V15.147H7.99961V14.847ZM7.84961 12.6001V14.997H8.14961V12.6001H7.84961ZM8.59961 11.8501C8.1854 11.8501 7.84961 12.1859 7.84961 12.6001H8.14961C8.14961 12.3516 8.35108 12.1501 8.59961 12.1501V11.8501ZM9.34961 12.6001C9.34961 12.1859 9.01382 11.8501 8.59961 11.8501V12.1501C8.84814 12.1501 9.04961 12.3516 9.04961 12.6001H9.34961ZM9.34961 14.997V12.6001H9.04961V14.997H9.34961ZM11.5996 14.847H9.19961V15.147H11.5996V14.847ZM12.3496 15.597C12.3496 15.1828 12.0138 14.847 11.5996 14.847V15.147C11.8481 15.147 12.0496 15.3484 12.0496 15.597H12.3496ZM11.5996 16.347C12.0138 16.347 12.3496 16.0112 12.3496 15.597H12.0496C12.0496 15.8455 11.8481 16.047 11.5996 16.047V16.347ZM9.19961 16.347H11.5996V16.047H9.19961V16.347ZM9.34961 18.6001V16.197H9.04961V18.6001H9.34961Z"
            fill="url(#paint3_linear_6145_50092)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6145_50092"
              x1="15.5401"
              y1={2}
              x2="23.8554"
              y2="11.9783"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#74736F" />
              <stop offset={1} stopColor="#898A8D" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_6145_50092"
              x1="8.5"
              y1={9}
              x2="16.8667"
              y2="19.0401"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#979797" />
              <stop offset={1} stopColor="#50555C" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_6145_50092"
              x1="8.6"
              y1={12}
              x2="13.2339"
              y2="17.5607"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#979797" />
              <stop offset={1} stopColor="#6A726C" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_6145_50092"
              x1="8.59961"
              y1="12.0001"
              x2="13.2335"
              y2="17.5608"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#979797" />
              <stop offset={1} stopColor="#50555C" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <ReceiveArrowIcon />
      </div>
    </div>
  );
};

export default CryptoCreditIcon;
