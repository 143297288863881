import React from "react";

const CryptoSellIcon = () => {
  return (
    <div>
      <svg
        width="25"
        height="25"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5007 1.08333C18.5161 1.08333 16.084 3.51541 16.084 6.5C16.084 9.48458 18.5161 11.9167 21.5007 11.9167C24.4852 11.9167 26.9173 9.48458 26.9173 6.5C26.9173 3.51541 24.4852 1.08333 21.5007 1.08333ZM23.624 6.90625H19.2907C19.0686 6.90625 18.8844 6.72208 18.8844 6.5C18.8844 6.27791 19.0686 6.09375 19.2907 6.09375H23.624C23.8461 6.09375 24.0302 6.27791 24.0302 6.5C24.0302 6.72208 23.8515 6.90625 23.624 6.90625Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M12 26.75C6.07 26.75 1.25 21.93 1.25 16C1.25 10.07 6.07 5.25 12 5.25C17.93 5.25 22.75 10.07 22.75 16C22.75 21.93 17.93 26.75 12 26.75ZM12 6.75C6.9 6.75 2.75 10.9 2.75 16C2.75 21.1 6.9 25.25 12 25.25C17.1 25.25 21.25 21.1 21.25 16C21.25 10.9 17.1 6.75 12 6.75Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M14.1993 16.75H8.7793C8.3693 16.75 8.0293 16.41 8.0293 16V11.67C8.0293 11.26 8.3693 10.92 8.7793 10.92H14.1993C15.7493 10.92 17.1193 12.28 17.1193 13.84C17.1193 15.44 15.8093 16.75 14.1993 16.75ZM9.5293 15.25H14.1993C14.9793 15.25 15.6193 14.61 15.6193 13.83C15.6193 13.17 14.9993 12.41 14.1993 12.41H9.5293V15.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M14.9693 21.08H8.7793C8.3693 21.08 8.0293 20.74 8.0293 20.33V16C8.0293 15.59 8.3693 15.25 8.7793 15.25H14.9693C16.7493 15.25 18.1993 16.56 18.1993 18.17C18.1993 19.78 16.7493 21.08 14.9693 21.08ZM9.5293 19.58H14.9693C15.9193 19.58 16.6993 18.94 16.6993 18.16C16.6993 17.38 15.9293 16.74 14.9693 16.74H9.5293V19.58Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M12.5703 23.25C12.1603 23.25 11.8203 22.91 11.8203 22.5V20.33C11.8203 19.92 12.1603 19.58 12.5703 19.58C12.9803 19.58 13.3203 19.92 13.3203 20.33V22.5C13.3203 22.91 12.9903 23.25 12.5703 23.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M9.86914 23.25C9.45914 23.25 9.11914 22.91 9.11914 22.5V20.33C9.11914 19.92 9.45914 19.58 9.86914 19.58C10.2791 19.58 10.6191 19.92 10.6191 20.33V22.5C10.6191 22.91 10.2791 23.25 9.86914 23.25Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M12.5703 12.42C12.1603 12.42 11.8203 12.08 11.8203 11.67V9.5C11.8203 9.09 12.1603 8.75 12.5703 8.75C12.9803 8.75 13.3203 9.09 13.3203 9.5V11.67C13.3203 12.08 12.9903 12.42 12.5703 12.42Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M9.86914 12.42C9.45914 12.42 9.11914 12.08 9.11914 11.67V9.5C9.11914 9.09 9.45914 8.75 9.86914 8.75C10.2791 8.75 10.6191 9.09 10.6191 9.5V11.67C10.6191 12.08 10.2791 12.42 9.86914 12.42Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M10.8408 12.42H7.05078C6.64078 12.42 6.30078 12.08 6.30078 11.67C6.30078 11.26 6.64078 10.92 7.05078 10.92H10.8408C11.2508 10.92 11.5908 11.26 11.5908 11.67C11.5908 12.08 11.2608 12.42 10.8408 12.42Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M10.8408 21.08H7.05078C6.64078 21.08 6.30078 20.74 6.30078 20.33C6.30078 19.92 6.64078 19.58 7.05078 19.58H10.8408C11.2508 19.58 11.5908 19.92 11.5908 20.33C11.5908 20.74 11.2608 21.08 10.8408 21.08Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default CryptoSellIcon;
