import React, { useEffect, useState } from "react";
import { FormInputField } from "../../components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks";
import { forgotPassword } from "../../redux/actions/ForgetPassword";
import CommonButton from "../../components/Buttons/CommonButton";
import SmallButton from "../../components/Buttons/SmallButton";
import { Logo } from "../../components/Icons";
import Footer from "../../components/Footer";
import LoadingModal from "../../components/Modals/LoadingModal";
import { setPageTitle } from "../../helper/metaDataHelper";

const ForgotPassword = () => {
  const forgotEmail = useForm("", "email");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isloadingModal = (status) => {
    setisLoading(status);
    setIsModalOpen(status);
  };

  useEffect(() => {
    setPageTitle("Forgot Password");
  }, []);

  const handelForgetPassword = async (e) => {
    e.preventDefault();
    // setError("");

    if (forgotEmail.validate()) {
      forgotEmail.setError("Please enter a valid email address");
      return;
    }

    isloadingModal(true);

    const res = await dispatch(forgotPassword({ email: forgotEmail.value }));
    if (res.status) {
      navigate("/reset-password");
      localStorage.setItem("forgetenEmail", forgotEmail.value);
      localStorage.setItem("allRestPasswordPage", true);
    } else {
      forgotEmail.setError(res.error);
    }
    isloadingModal(false);
  };

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4 py-8 min-h-dvh flex-1 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center justify-center max-w-[384px] w-full h-auto space-y-4">
          <div className="mb-5">
            <Logo className="text-gray-500 max-md:w-52 max-md:h-auto w-[257px] h-[71px]" />
          </div>

          <div className="flex flex-col items-start justify-center w-full">
            <SmallButton href={"/signin"} text={"Back"} back={true} />

            <p className="text-body-text text-[42px] font-medium tracking-tight">
              Forgot password
            </p>

            <div className="w-full mt-6 space-y-4">
              <FormInputField
                id={"email"}
                label={"Email"}
                type={"email"}
                placeholder={"Enter your email address"}
                onChange={(e) => {
                  forgotEmail.onChange(e);
                }}
                {...forgotEmail}
              />
              <CommonButton
                disabled={!forgotEmail.value}
                onClick={handelForgetPassword}
              >
                Email me recovery OTP
              </CommonButton>
              <div className="flex items-center justify-center pt-4 space-x-2">
                <p className="text-body-text">Trouble logging in?</p>

                {/* <SmallButton text={"Get Help?"} /> */}
              </div>
            </div>
          </div>

          <Footer />
          {/* Second sections */}
          {/* disable this for monfi anad asia bank */}
          {/* <Slider /> */}
        </div>
      </div>

      <LoadingModal
        isLoading={isLoading}
        setisLoading={setisLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </main>
  );
};

export default ForgotPassword;
