import React from "react";
import SendArrowIcon from "../SendArrowIcon";

const CryptoDebitIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-secondary-icon-bg rounded-full">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.9114 9.52809C22.392 12.7465 19.5942 15.0198 16.4391 14.9868L16.4487 14.9275C16.7607 11.0477 14.1528 7.43613 10.3421 6.50722L10.3125 6.50245C11.2735 3.49716 14.3114 1.55632 17.5298 2.07564C21.074 2.64753 23.4832 5.98393 22.9114 9.52809Z"
            fill="#50555C"
          />
          <path
            d="M22.9114 9.52809C22.392 12.7465 19.5942 15.0198 16.4391 14.9868L16.4487 14.9275C16.7607 11.0477 14.1528 7.43613 10.3421 6.50722L10.3125 6.50245C11.2735 3.49716 14.3114 1.55632 17.5298 2.07564C21.074 2.64753 23.4832 5.98393 22.9114 9.52809Z"
            fill="#172C0F"
          />
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="#50555C"
          />
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="url(#paint0_linear_6145_50100)"
          />
          <path
            d="M14.98 14.98C14.73 11.81 12.19 9.27 9.02 9.02C8.85 9.01 8.67 9 8.5 9C4.91 9 2 11.91 2 15.5C2 19.09 4.91 22 8.5 22C12.09 22 15 19.09 15 15.5C15 15.33 14.99 15.15 14.98 14.98Z"
            fill="url(#paint1_linear_6145_50100)"
            stroke="url(#paint2_linear_6145_50100)"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.53817 13.5206C3.80329 13.5979 3.95559 13.8754 3.87835 14.1405C3.23033 16.3647 4.37721 18.6858 6.46836 19.5537C6.7234 19.6596 6.87735 19.9346 6.80011 20.1997C6.72287 20.4648 6.44395 20.6192 6.18683 20.5185C3.56583 19.4919 2.11567 16.6155 2.91827 13.8608C2.99551 13.5957 3.27305 13.4434 3.53817 13.5206Z"
            fill="#252525"
            fillOpacity="0.28"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6145_50100"
              x1="15.5401"
              y1={2}
              x2="23.8554"
              y2="11.9783"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#979797" />
              <stop offset={1} stopColor="#50555C" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_6145_50100"
              x1="8.5"
              y1={9}
              x2="16.8667"
              y2="19.0401"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#74736F" />
              <stop offset={1} stopColor="#6A726C" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_6145_50100"
              x1="8.5"
              y1={9}
              x2="16.8667"
              y2="19.0401"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#979797" />
              <stop offset={1} stopColor="#CCCCCC" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <SendArrowIcon />
      </div>
    </div>
  );
};

export default CryptoDebitIcon;
