import React from "react";

const LogoutIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Logout">
          <g id="Group 12572">
            <path
              id="Vector (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.9244 6.92832C14.2715 6.58119 14.8344 6.58119 15.1815 6.92832L19.6259 11.3728C19.9731 11.7199 19.9731 12.2827 19.6259 12.6298L15.1815 17.0743C14.8344 17.4214 14.2715 17.4214 13.9244 17.0743C13.5773 16.7272 13.5773 16.1643 13.9244 15.8172L17.7403 12.0013L13.9244 8.1854C13.5773 7.83826 13.5773 7.27545 13.9244 6.92832Z"
              className="dark:fill-icon-btn fill-primary-item"
            />
            <path
              id="Vector (Stroke)_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4414 12.0022C10.4414 11.5113 10.8394 11.1133 11.3303 11.1133H18.997C19.4879 11.1133 19.8858 11.5113 19.8858 12.0022C19.8858 12.4931 19.4879 12.8911 18.997 12.8911H11.3303C10.8394 12.8911 10.4414 12.4931 10.4414 12.0022Z"
              className="dark:fill-icon-btn fill-primary-item"
            />
            <path
              id="Vector (Stroke)_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.77604 5.89106C7.54029 5.89106 7.3142 5.98471 7.1475 6.15141C6.9808 6.31811 6.88715 6.5442 6.88715 6.77995V17.2244C6.88715 17.4601 6.9808 17.6862 7.1475 17.8529C7.3142 18.0196 7.54029 18.1133 7.77604 18.1133H11.3316C11.8225 18.1133 12.2205 18.5112 12.2205 19.0022C12.2205 19.4931 11.8225 19.8911 11.3316 19.8911H7.77604C7.0688 19.8911 6.39052 19.6101 5.89042 19.11C5.39033 18.6099 5.10938 17.9316 5.10938 17.2244V6.77995C5.10938 6.0727 5.39033 5.39443 5.89042 4.89433C6.39052 4.39423 7.0688 4.11328 7.77604 4.11328H11.3316C11.8225 4.11328 12.2205 4.51125 12.2205 5.00217C12.2205 5.49309 11.8225 5.89106 11.3316 5.89106H7.77604Z"
              className="dark:fill-icon-btn fill-primary-item"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default LogoutIcon;
