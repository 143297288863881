import React from "react";

const PrimaryButton = ({ text, icon, ...props }) => {
  return (
    <button
      className="flex w-full items-center justify-center space-x-2 flex-grow bg-button-bg py-3 px-4 rounded-full cursor-pointer disabled:cursor-not-allowed hover:bg-button-hover-bg disabled:bg-[#70CC4A] disabled:bg-opacity-50"
      {...props}
    >
      {icon && icon}
      <p className="text-button-text text-sm font-medium leading-normal tracking-tight">
        {text}
      </p>
    </button>
  );
};

export default PrimaryButton;
