import React from "react";
import TransferTypeButton from "./partials/TransferTypeButton";
import {
  ExchangeIcon,
  ExternalTransferIcon,
  InternalTransferIcon,
} from "../../../Icons";
import { useSelector } from "react-redux";

const TransferType = ({ type, setType }) => {
  const { name } = useSelector((state) => state.ownCompany.company);

  const TransferTypesButtons = [
    {
      icon: <ExchangeIcon />,
      title: "Exchange",
      subTitle: "Convert currency within your accounts",
      type: "exchange",
    },
    {
      icon: <InternalTransferIcon />,
      title: "Internal Transfer",
      subTitle: `Transfer Within ${name.split(" ")[0]} bank`,
      type: "internal-transfer",
    },
    {
      icon: <ExternalTransferIcon />,
      title: "External Transfer",
      subTitle: `Transfer Outside  ${name.split(" ")[0]} bank`,
      type: "external-transfer",
    },
  ];
  return (
    <>
      {TransferTypesButtons.map((button) => (
        <div key={button.type} onClick={() => setType(button.type)}>
          <TransferTypeButton
            icon={button.icon}
            title={button.title}
            subTitle={button.subTitle}
            type={button.type}
            selectedType={type}
          />
        </div>
      ))}
    </>
  );
};

export default TransferType;
