const CameraIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.76017 22H17.2402C20.0002 22 21.1002 20.31 21.2302 18.25L21.7502 9.99C21.8902 7.83 20.1702 6 18.0002 6C17.3902 6 16.8302 5.65 16.5502 5.11L15.8302 3.66C15.3702 2.75 14.1702 2 13.1502 2H10.8602C9.83017 2 8.63017 2.75 8.17017 3.66L7.45017 5.11C7.17017 5.65 6.61017 6 6.00017 6C3.83017 6 2.11017 7.83 2.25017 9.99L2.77017 18.25C2.89017 20.31 4.00017 22 6.76017 22ZM9.75 8C9.75 7.58579 10.0858 7.25 10.5 7.25H13.5C13.9142 7.25 14.25 7.58579 14.25 8C14.25 8.41421 13.9142 8.75 13.5 8.75H10.5C10.0858 8.75 9.75 8.41421 9.75 8ZM12 10.75C9.79579 10.75 8 12.5458 8 14.75C8 16.9542 9.79579 18.75 12 18.75C14.2042 18.75 16 16.9542 16 14.75C16 12.5458 14.2042 10.75 12 10.75ZM9.5 14.75C9.5 13.3742 10.6242 12.25 12 12.25C13.3758 12.25 14.5 13.3742 14.5 14.75C14.5 16.1258 13.3758 17.25 12 17.25C10.6242 17.25 9.5 16.1258 9.5 14.75Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default CameraIcon;
