import React from "react";
import AddNewCardModal from "../../Modals/Card/AddNewCardModal";
import CommonButton from "../../Buttons/CommonButton";

const EmptyCard = () => {
  return (
    <>
      <div className="space-y-6 border border-dropdown-popup-bg bg-primarycard-gradient px-3 py-6 shadow-box-shadow flex flex-col items-center rounded-3xl">
        <div className="flex items-center justify-center">
          <p className="text-body-text font-semibold text-xl md:text-2xl">
            It's time for your first digital card!
          </p>
        </div>
        <img
          src="assets/logos/add-card.png"
          alt="card"
          className="md:block relative rounded-2xl w-full md:w-[400px]  md:h-[250px] h-full bg-cover bg-center bg-no-repeat"
        />
        <div className="flex flex-col justify-between space-y-4 md:w-[400px] w-full">
          <AddNewCardModal>
            <CommonButton>Request a new card</CommonButton>
          </AddNewCardModal>
        </div>
      </div>
    </>
  );
};

export default EmptyCard;
