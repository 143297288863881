import React from "react";

const EmptyExchangeIcons = () => {
  return (
    <>
      <div className="hidden dark:block">
        <svg
          width={150}
          height={142}
          viewBox="0 0 150 142"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_5030_48085)">
            <ellipse
              cx="83.5449"
              cy="96.8672"
              rx="31.5156"
              ry="11.8183"
              fill="#141A15"
            />
          </g>
          <path
            d="M120.943 50.552C121.006 57.9935 119.177 65.3305 115.626 71.8772C112.076 78.4239 106.921 83.9665 100.643 87.9869C94.3649 92.0073 87.1686 94.3742 79.7269 94.8662C72.2852 95.3583 64.8411 93.9596 58.0906 90.8007L42.098 98.8885C41.6121 99.1308 41.0667 99.2285 40.5269 99.1698C39.9871 99.1111 39.4758 98.8985 39.054 98.5575C38.6322 98.2165 38.3177 97.7614 38.1483 97.2467C37.9788 96.732 37.9615 96.1794 38.0983 95.6549L42.5774 78.6051C38.1579 73.1417 35.1139 66.7014 33.6996 59.8223C32.2853 52.9431 32.5419 45.8251 34.4479 39.0632C36.3539 32.3012 39.8541 26.0917 44.6557 20.9537C49.4573 15.8157 55.421 11.8984 62.0483 9.52936C68.6755 7.16029 75.7738 6.40821 82.7498 7.33599C89.7258 8.26377 96.3769 10.8445 102.147 14.8623C107.917 18.8802 117.664 28.2971 120.943 34.5098C124.221 40.7224 120.937 43.5503 120.993 50.5733L120.943 50.552Z"
            fill="#455541"
          />
          <path
            d="M126.567 62.7883C125.586 70.1651 122.747 77.1732 118.314 83.1576C113.881 89.142 108 93.9071 101.22 97.0077C94.4401 100.108 86.9831 101.443 79.5459 100.887C72.1087 100.331 64.9343 97.9021 58.6932 93.8281L41.7247 99.5939C41.2097 99.7657 40.656 99.7859 40.1297 99.6521C39.6035 99.5183 39.127 99.2362 38.7572 98.8394C38.3873 98.4426 38.1398 97.948 38.0442 97.4146C37.9486 96.8812 38.0089 96.3316 38.2179 95.8315L45.043 79.578C41.4331 73.5491 39.322 66.7455 38.8861 59.7361C38.4502 52.7266 39.7021 45.7149 42.5373 39.2869C45.3725 32.8589 49.7086 27.2014 55.1832 22.7873C60.6577 18.3732 67.1116 15.3308 74.0056 13.9142C80.8995 12.4976 88.0331 12.7481 94.8101 14.6447C101.587 16.5413 107.811 20.029 112.961 24.8161C118.111 29.6033 122.038 35.5509 124.413 42.1618C126.788 48.7728 127.543 55.855 126.614 62.8165L126.567 62.7883Z"
            fill="url(#paint0_linear_5030_48085)"
          />
          <path
            d="M82.5067 79.0145C86.0227 79.0145 88.4782 76.6147 88.4782 73.2661C88.4782 69.8618 86.0227 67.462 82.5067 67.462C79.0466 67.462 76.5352 69.8618 76.5352 73.2661C76.5352 76.6147 79.0466 79.0145 82.5067 79.0145ZM87.976 38.0508H77.0374V48.9893L79.8279 64.1135H85.1297L87.976 48.9893V38.0508Z"
            fill="#AFBEAF"
          />
          <path
            d="M149.277 45.3465C149.307 45.2921 149.325 45.232 149.33 45.1701C149.336 45.1082 149.328 45.0459 149.307 44.9873C149.287 44.9286 149.254 44.875 149.211 44.8299C149.168 44.7848 149.117 44.7493 149.059 44.7256C147.382 44.0571 141.45 42.0726 138.208 46.0734C136.039 48.7029 132.973 50.4362 129.601 50.9392C129.517 50.9505 129.437 50.9873 129.374 51.0446C129.31 51.1019 129.265 51.1771 129.245 51.2603C129.225 51.3435 129.231 51.4308 129.261 51.5108C129.292 51.5908 129.345 51.6597 129.416 51.7086C132.281 53.7408 141.747 58.9196 149.277 45.3465Z"
            fill="url(#paint1_linear_5030_48085)"
          />
          <path
            d="M23.6198 73.405C23.6437 73.3613 23.6582 73.313 23.6622 73.2633C23.6663 73.2136 23.6598 73.1636 23.6433 73.1166C23.6268 73.0696 23.6006 73.0265 23.5664 72.9903C23.5322 72.954 23.4907 72.9254 23.4447 72.9062C22.1022 72.3756 17.332 70.7838 14.7267 73.9675C12.9882 76.077 10.5315 77.4697 7.82864 77.8781C7.76078 77.8872 7.69725 77.9166 7.64639 77.9624C7.59554 78.0083 7.55973 78.0684 7.54366 78.135C7.52759 78.2015 7.53202 78.2714 7.55637 78.3354C7.58071 78.3994 7.62383 78.4546 7.68007 78.4936C9.96703 80.1491 17.5708 84.3092 23.6198 73.405Z"
            fill="url(#paint2_linear_5030_48085)"
          />
          <path
            d="M136.224 67.3864C136.204 67.35 136.192 67.3097 136.188 67.2682C136.185 67.2266 136.191 67.1849 136.205 67.1457C136.219 67.1065 136.241 67.0707 136.27 67.0408C136.299 67.011 136.334 66.9877 136.373 66.9725C137.498 66.5215 141.483 65.1896 143.658 67.8745C145.117 69.6408 147.177 70.8051 149.442 71.1431C149.502 71.1486 149.559 71.173 149.604 71.2127C149.65 71.2524 149.682 71.3054 149.695 71.3642C149.709 71.4231 149.703 71.4847 149.68 71.5402C149.656 71.5958 149.616 71.6425 149.564 71.6737C147.643 73.048 141.281 76.5236 136.224 67.3864Z"
            fill="url(#paint3_linear_5030_48085)"
          />
          <path
            d="M0.0592421 50.1665C0.0264923 50.1058 0.00681559 50.0389 0.00148021 49.9702C-0.00385517 49.9015 0.00526914 49.8324 0.0282651 49.7674C0.051261 49.7024 0.0876183 49.643 0.134994 49.5929C0.182369 49.5428 0.239712 49.5032 0.303326 49.4767C2.16048 48.7391 8.73482 46.5424 12.3271 50.9677C14.7334 53.8854 18.1368 55.8064 21.8782 56.3588C21.9717 56.3725 22.059 56.4141 22.1287 56.4781C22.1983 56.542 22.2472 56.6254 22.2689 56.7175C22.2906 56.8095 22.2842 56.906 22.2505 56.9943C22.2168 57.0827 22.1573 57.1589 22.0798 57.213C18.8908 59.4629 8.40053 65.2041 0.0592421 50.1665Z"
            fill="url(#paint4_linear_5030_48085)"
          />
          <path
            d="M21.9773 31.3433L29.9749 33.5908L24.0298 39.3932L21.9773 31.3433Z"
            fill="url(#paint5_linear_5030_48085)"
          />
          <path
            d="M133.79 4.8125L136.029 14.0852L145.301 16.3235L136.029 18.5619L133.79 27.8346L131.552 18.5619L122.279 16.3235L131.552 14.0852L133.79 4.8125Z"
            fill="url(#paint6_linear_5030_48085)"
          />
          <path
            d="M51.4983 118.979L53.7367 128.251L63.0094 130.49L53.7367 132.728L51.4983 142.001L49.26 132.728L39.9873 130.49L49.26 128.251L51.4983 118.979Z"
            fill="url(#paint7_linear_5030_48085)"
          />
          <path
            d="M35.6913 117.699C35.6913 119.553 34.1882 121.057 32.3339 121.057C30.4797 121.057 28.9766 119.553 28.9766 117.699C28.9766 115.845 30.4797 114.342 32.3339 114.342C34.1882 114.342 35.6913 115.845 35.6913 117.699Z"
            fill="url(#paint8_linear_5030_48085)"
          />
          <circle
            cx="36.3906"
            cy="13.9648"
            r="3.35738"
            fill="url(#paint9_linear_5030_48085)"
          />
          <circle
            cx="141.284"
            cy="112.484"
            r="3.35738"
            fill="url(#paint10_linear_5030_48085)"
          />
          <defs>
            <filter
              id="filter0_f_5030_48085"
              x="30.1435"
              y="63.163"
              width="106.803"
              height="67.4083"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="10.9429"
                result="effect1_foregroundBlur_5030_48085"
              />
            </filter>
            <linearGradient
              id="paint0_linear_5030_48085"
              x1="94.826"
              y1="93.855"
              x2="66.0003"
              y2="26.6742"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5030_48085"
              x1="142.066"
              y1="53.5143"
              x2="139.927"
              y2="44.2953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_5030_48085"
              x1="17.8326"
              y1="79.9642"
              x2="16.1144"
              y2="72.5631"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_5030_48085"
              x1="144.817"
              y1="72.8896"
              x2="143.375"
              y2="66.6837"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_5030_48085"
              x1="14.2266"
              y1="59.2141"
              x2="11.8589"
              y2="49.0017"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_5030_48085"
              x1="29.0834"
              y1="36.7222"
              x2="21.7204"
              y2="33.7117"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_5030_48085"
              x1="136.979"
              y1="25.9628"
              x2="129.379"
              y2="8.44956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_5030_48085"
              x1="54.6868"
              y1="140.129"
              x2="47.0869"
              y2="122.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_5030_48085"
              x1="33.2639"
              y1="120.511"
              x2="31.0473"
              y2="115.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_5030_48085"
              x1="37.3205"
              y1="16.7763"
              x2="35.1039"
              y2="11.6682"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_5030_48085"
              x1="142.214"
              y1="115.296"
              x2="139.997"
              y2="110.188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#455541" />
              <stop offset={1} stopColor="#69776E" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="block dark:hidden">
        <svg
          width={150}
          height={142}
          viewBox="0 0 150 142"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_1371_56115)">
            <ellipse
              cx="83.5449"
              cy="96.8672"
              rx="31.5156"
              ry="11.8183"
              fill="#DDDAD2"
            />
          </g>
          <path
            d="M120.943 50.552C121.006 57.9935 119.177 65.3305 115.626 71.8772C112.076 78.4239 106.921 83.9665 100.643 87.9869C94.3649 92.0073 87.1686 94.3742 79.7269 94.8662C72.2852 95.3583 64.8411 93.9596 58.0906 90.8007L42.098 98.8885C41.6121 99.1308 41.0667 99.2285 40.5269 99.1698C39.9871 99.1111 39.4758 98.8985 39.054 98.5575C38.6322 98.2165 38.3177 97.7614 38.1483 97.2467C37.9788 96.732 37.9615 96.1794 38.0983 95.6549L42.5774 78.6051C38.1579 73.1417 35.1139 66.7014 33.6996 59.8223C32.2853 52.9431 32.5419 45.8251 34.4479 39.0632C36.3539 32.3012 39.8541 26.0917 44.6557 20.9537C49.4573 15.8157 55.421 11.8984 62.0483 9.52936C68.6755 7.16029 75.7738 6.40821 82.7498 7.33599C89.7258 8.26377 96.3769 10.8445 102.147 14.8623C107.917 18.8802 117.664 28.2971 120.943 34.5098C124.221 40.7224 120.937 43.5503 120.993 50.5733L120.943 50.552Z"
            fill="#D7DFD6"
          />
          <path
            d="M126.567 62.7883C125.586 70.1651 122.747 77.1732 118.314 83.1576C113.881 89.142 108 93.9071 101.22 97.0077C94.4401 100.108 86.9831 101.443 79.5459 100.887C72.1087 100.331 64.9343 97.9021 58.6932 93.8281L41.7247 99.5939C41.2097 99.7657 40.656 99.7859 40.1297 99.6521C39.6035 99.5183 39.127 99.2362 38.7572 98.8394C38.3873 98.4426 38.1398 97.948 38.0442 97.4146C37.9486 96.8812 38.0089 96.3316 38.2179 95.8315L45.043 79.578C41.4331 73.5491 39.322 66.7455 38.8861 59.7361C38.4502 52.7266 39.7021 45.7149 42.5373 39.2869C45.3725 32.8589 49.7086 27.2014 55.1832 22.7873C60.6577 18.3732 67.1116 15.3308 74.0056 13.9142C80.8995 12.4976 88.0331 12.7481 94.8101 14.6447C101.587 16.5413 107.811 20.029 112.961 24.8161C118.111 29.6033 122.038 35.5509 124.413 42.1618C126.788 48.7728 127.543 55.855 126.614 62.8165L126.567 62.7883Z"
            fill="url(#paint0_linear_1371_56115)"
          />
          <path
            d="M82.5067 79.0145C86.0227 79.0145 88.4782 76.6147 88.4782 73.2661C88.4782 69.8618 86.0227 67.462 82.5067 67.462C79.0466 67.462 76.5352 69.8618 76.5352 73.2661C76.5352 76.6147 79.0466 79.0145 82.5067 79.0145ZM87.976 38.0508H77.0374V48.9893L79.8279 64.1135H85.1297L87.976 48.9893V38.0508Z"
            fill="#AFBEAF"
          />
          <path
            d="M149.277 45.3465C149.307 45.2921 149.325 45.232 149.331 45.1701C149.336 45.1082 149.328 45.0459 149.307 44.9873C149.287 44.9286 149.254 44.875 149.211 44.8299C149.169 44.7848 149.117 44.7493 149.059 44.7256C147.383 44.0571 141.45 42.0726 138.208 46.0734C136.039 48.7029 132.973 50.4362 129.602 50.9392C129.517 50.9505 129.437 50.9873 129.374 51.0446C129.31 51.1019 129.266 51.1771 129.245 51.2603C129.225 51.3435 129.231 51.4308 129.261 51.5108C129.292 51.5908 129.346 51.6597 129.416 51.7086C132.281 53.7408 141.748 58.9196 149.277 45.3465Z"
            fill="url(#paint1_linear_1371_56115)"
          />
          <path
            d="M23.6194 73.405C23.6433 73.3613 23.6578 73.313 23.6619 73.2633C23.6659 73.2136 23.6595 73.1636 23.643 73.1166C23.6265 73.0696 23.6002 73.0265 23.566 72.9903C23.5318 72.954 23.4903 72.9254 23.4443 72.9062C22.1019 72.3756 17.3316 70.7838 14.7263 73.9675C12.9878 76.077 10.5312 77.4697 7.82828 77.8781C7.76041 77.8872 7.69689 77.9166 7.64603 77.9624C7.59517 78.0083 7.55936 78.0684 7.54329 78.135C7.52723 78.2015 7.53166 78.2714 7.556 78.3354C7.58034 78.3994 7.62346 78.4546 7.6797 78.4936C9.96666 80.1491 17.5704 84.3092 23.6194 73.405Z"
            fill="url(#paint2_linear_1371_56115)"
          />
          <path
            d="M136.225 67.3864C136.205 67.35 136.192 67.3097 136.189 67.2682C136.186 67.2266 136.191 67.1849 136.205 67.1457C136.219 67.1065 136.242 67.0707 136.271 67.0408C136.3 67.011 136.335 66.9877 136.373 66.9725C137.498 66.5215 141.483 65.1896 143.659 67.8745C145.117 69.6408 147.177 70.8051 149.443 71.1431C149.503 71.1486 149.56 71.173 149.605 71.2127C149.65 71.2524 149.682 71.3054 149.696 71.3642C149.709 71.4231 149.704 71.4847 149.68 71.5402C149.657 71.5958 149.616 71.6425 149.565 71.6737C147.644 73.048 141.282 76.5236 136.225 67.3864Z"
            fill="url(#paint3_linear_1371_56115)"
          />
          <path
            d="M0.0592421 50.1665C0.0264923 50.1058 0.00681559 50.0389 0.00148021 49.9702C-0.00385517 49.9015 0.00526914 49.8324 0.0282651 49.7674C0.051261 49.7024 0.0876183 49.643 0.134994 49.5929C0.182369 49.5428 0.239712 49.5032 0.303326 49.4767C2.16048 48.7391 8.73482 46.5424 12.3271 50.9677C14.7334 53.8854 18.1368 55.8064 21.8782 56.3588C21.9717 56.3725 22.059 56.4141 22.1287 56.4781C22.1983 56.542 22.2472 56.6254 22.2689 56.7175C22.2906 56.8095 22.2842 56.906 22.2505 56.9943C22.2168 57.0827 22.1573 57.1589 22.0798 57.213C18.8908 59.4629 8.40053 65.2041 0.0592421 50.1665Z"
            fill="url(#paint4_linear_1371_56115)"
          />
          <path
            d="M21.9771 31.3433L29.9747 33.5908L24.0296 39.3932L21.9771 31.3433Z"
            fill="url(#paint5_linear_1371_56115)"
          />
          <path
            d="M133.79 4.8125L136.029 14.0852L145.301 16.3235L136.029 18.5619L133.79 27.8346L131.552 18.5619L122.279 16.3235L131.552 14.0852L133.79 4.8125Z"
            fill="url(#paint6_linear_1371_56115)"
          />
          <path
            d="M51.4981 118.979L53.7365 128.251L63.0091 130.49L53.7365 132.728L51.4981 142.001L49.2597 132.728L39.9871 130.49L49.2597 128.251L51.4981 118.979Z"
            fill="url(#paint7_linear_1371_56115)"
          />
          <path
            d="M35.6908 117.699C35.6908 119.553 34.1877 121.057 32.3335 121.057C30.4792 121.057 28.9761 119.553 28.9761 117.699C28.9761 115.845 30.4792 114.342 32.3335 114.342C34.1877 114.342 35.6908 115.845 35.6908 117.699Z"
            fill="url(#paint8_linear_1371_56115)"
          />
          <circle
            cx="36.3901"
            cy="13.9648"
            r="3.35738"
            fill="url(#paint9_linear_1371_56115)"
          />
          <circle
            cx="141.284"
            cy="112.484"
            r="3.35738"
            fill="url(#paint10_linear_1371_56115)"
          />
          <defs>
            <filter
              id="filter0_f_1371_56115"
              x="30.1435"
              y="63.163"
              width="106.803"
              height="67.4083"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="10.9429"
                result="effect1_foregroundBlur_1371_56115"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1371_56115"
              x1="94.826"
              y1="93.855"
              x2="66.0003"
              y2="26.6742"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1371_56115"
              x1="142.066"
              y1="53.5143"
              x2="139.927"
              y2="44.2953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1371_56115"
              x1="17.8322"
              y1="79.9642"
              x2="16.1141"
              y2="72.5631"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1371_56115"
              x1="144.817"
              y1="72.8896"
              x2="143.375"
              y2="66.6837"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1371_56115"
              x1="14.2266"
              y1="59.2141"
              x2="11.8589"
              y2="49.0017"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1371_56115"
              x1="29.0831"
              y1="36.7222"
              x2="21.7201"
              y2="33.7117"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_1371_56115"
              x1="136.979"
              y1="25.9628"
              x2="129.379"
              y2="8.44956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_1371_56115"
              x1="54.6865"
              y1="140.129"
              x2="47.0866"
              y2="122.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_1371_56115"
              x1="33.2634"
              y1="120.511"
              x2="31.0468"
              y2="115.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_1371_56115"
              x1="37.3201"
              y1="16.7763"
              x2="35.1034"
              y2="11.6682"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_1371_56115"
              x1="142.214"
              y1="115.296"
              x2="139.997"
              y2="110.188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CFD9CC" />
              <stop offset={1} stopColor="#E8EDEA" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default EmptyExchangeIcons;
