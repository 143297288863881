import React, { useCallback, useEffect, useState } from "react";
import { Logo } from "../../components/Icons";
import { FormInputField, Label } from "../../components";
import CommonButton from "../../components/Buttons/CommonButton";
import SmallButton from "../../components/Buttons/SmallButton";
import Footer from "../../components/Footer";
import { useDispatch } from "react-redux";
import { useForm } from "../../hooks";
import { sendGuestOtp, verifyEmail } from "../../redux/actions/register/otp";
import RegistrationOTPModal from "../../components/Modals/RegistrationOTPModal";
import CountriesInput from "../../components/Common/CountriesInput";
import OptionButton from "../../components/Common/OptionButton";
import useAllCountries from "../../hooks/useAllCountries";
import { setPageTitle } from "../../helper/metaDataHelper";
import PasswordToggleIcon from "../../components/Common/PasswordToggleIcon";
import ErrorMessage from "../../components/Common/ErrorMessage";

const Signup = () => {
  const allCountries = useAllCountries();
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const name = useForm("");
  const companyType = useForm("");
  const email = useForm("", "email");
  const mobileNumber = useForm("");
  const password = useForm("", "password");
  const confirmPassword = useForm("", "password");

  const [showPassword, setShowPassword] = useState({
    new_password: false,
    new_password_confirmation: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (allCountries) {
      setCountry(allCountries[223]);
    }
  }, [allCountries]);

  useEffect(() => {
    setPageTitle("Client Registration");
  }, []);

  // Centralized validation logic
  const validateForm = useCallback(() => {
    let isValid = true;

    if (!companyType.value) {
      setError("Please select account type");
      companyType.setError(true);
      isValid = false;
    }

    if (!name.value) {
      name.setError("Please enter your name");
      isValid = false;
    }
    if (!mobileNumber.value) {
      mobileNumber.setError("Mobile is required");
      isValid = false;
    }
    if (email.validate() || password.validate() || confirmPassword.validate()) {
      isValid = false;
    }
    if (password.value !== confirmPassword.value) {
      password.setError(true);
      confirmPassword.setError("Passwords do not match");
      isValid = false;
    }

    return isValid;
  }, [companyType, name, email, password, confirmPassword, mobileNumber]);

  // Handle registration process
  const handleRegister = async () => {
    setError("");
    if (!validateForm()) return;

    setIsLoading(true);
    setIsModalOpen(true);
    const checkEmail = await dispatch(verifyEmail(email.value));

    if (checkEmail.success) {
      setIsLoading(false);
      setIsModalOpen(false);
      email.setError(checkEmail.message);
      return;
    }

    const data = {
      name: name.value,
      email_address: email.value,
      mobile_number: mobileNumber.value,
      password: password.value,
      country_code_id: country.id,
      account_type: companyType.value,
    };

    localStorage.setItem("userDraftData", JSON.stringify(data));
    localStorage.setItem("account_type", companyType.value);

    const res = await dispatch(sendGuestOtp(email.value));

    if (res) {
      setIsModalOpen(true);
    } else {
      setError(res.data.message);
    }
    setIsLoading(false);
  };

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4 py-8 min-h-dvh flex-1 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center justify-center max-w-[384px] w-full h-auto space-y-4">
          <Logo className="text-gray-500 max-md:w-52 max-md:h-auto w-[257px] h-[71px]" />
          <div className="flex flex-col items-center justify-center w-full">
            <p className="text-body-text text-4xl font-medium tracking-tight">
              Create your account
            </p>
            <div className="justify-center items-center flex">
              <p className="text-paragraph-text text-sm font-normal tracking-tight">
                Already having an account?
              </p>
              <SmallButton href={"/signin"} text={"Log in"} />
            </div>

            <div className="w-full space-y-4 bg-body-bg mt-3">
              {error && (
                <ErrorMessage message={error} onClose={() => setError("")} />
              )}
              <FormInputField
                id={"name"}
                name={"name"}
                label={"Your name / Business name"}
                type={"text"}
                placeholder={"Enter your full name or business name"}
                {...name}
              />
              <div className="flex items-center justify-between space-x-3">
                <OptionButton
                  lable={"Individual Account"}
                  value={"individual"}
                  option={companyType.value}
                  setOption={companyType.setValue}
                  error={companyType.error}
                />
                <OptionButton
                  lable={"Company Account"}
                  value={"company"}
                  option={companyType.value}
                  setOption={companyType.setValue}
                  error={companyType.error}
                />
              </div>

              <div className="flex items-center justify-between space-x-3">
                {allCountries.length > 0 && country && (
                  <CountriesInput
                    id="country"
                    countries={allCountries}
                    SelectedCountry={country}
                    onChange={setCountry}
                  >
                    <div className="flex items-center justify-center space-x-3 px-4">
                      {country.flag && (
                        <img
                          className="rounded-full w-8 h-8"
                          src={country.flag}
                          alt="flag"
                        />
                      )}
                      <Label
                        htmlFor="country"
                        className="btext-sm font-medium text-body-text"
                        text={`${
                          country.phonecode
                            ? "+" + country.phonecode
                            : "country"
                        }`}
                      />
                    </div>
                  </CountriesInput>
                )}

                <FormInputField
                  id={"phone"}
                  name="phone"
                  label={"Phone"}
                  type={"number"}
                  placeholder={"Enter your phone number"}
                  {...mobileNumber}
                  error={mobileNumber.error ? true : false}
                />
              </div>
              {mobileNumber.error && (
                <span className="text-highlighter-red text-sm">
                  {mobileNumber.error}
                </span>
              )}

              <FormInputField
                id={"email"}
                name={"email"}
                label={"Email"}
                type={"email"}
                placeholder={"Enter your email"}
                {...email}
              />
              <FormInputField
                type={showPassword.new_password ? "text" : "password"}
                id={"password"}
                name={"password"}
                label={"Password"}
                placeholder={"Enter your password"}
                {...password}
                icon={
                  <PasswordToggleIcon
                    isVisible={showPassword.new_password}
                    onToggle={() => togglePasswordVisibility("new_password")}
                  />
                }
              />
              <FormInputField
                type={
                  showPassword.new_password_confirmation ? "text" : "password"
                }
                id={"confirm_password"}
                name={"confirm_password"}
                label={"Confirm Password"}
                placeholder={"Re-enter your password"}
                {...confirmPassword}
                icon={
                  <PasswordToggleIcon
                    isVisible={showPassword.new_password_confirmation}
                    onToggle={() =>
                      togglePasswordVisibility("new_password_confirmation")
                    }
                  />
                }
              />

              <CommonButton onClick={handleRegister}>Sign up</CommonButton>

              <p className="text-paragraph-text text-sm text-center pb-6">
                By signing up, you accept our
                <a
                  href="https://cibmy.com/terms-and-conditions/"
                  className="text-primary-item px-2 cursor-pointer"
                >
                  Terms & Conditions
                </a>
              </p>
            </div>

            <Footer />
          </div>
          {/* Second sections */}
          {/* disable this for monfi anad asia bank */}
          {/* <Slider /> */}
        </div>
      </div>
      <RegistrationOTPModal
        isLoading={isLoading}
        setisLoading={setIsLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </main>
  );
};

export default Signup;
