import React from "react";
import { FormatBalance, GetCurrenySymbol } from "../../../../helper";

const Accounts = ({ account }) => {
  return (
    <div
      key={account.id}
      className="flex justify-between border border-divider-border transition-all ease-in-out duration-500 borderAnimation shadow-sm rounded-3xl hover:bg-icon-btn-hover
dark:hover:bg-active-state-bg bg-primarycard-gradient px-6 py-8"
    >
      <div className="flex">
        <img
          src={account.logo}
          alt={account.title}
          className="w-10 h-10 rounded-full"
        />
        <div className="ml-3">
          <p className="dark:text-white text-body-text text-sm font-semibold max-w-36 line-clamp-1">
            {account.title}
          </p>
          <p className="text-xs dark:text-white text-paragraph-text">
            {account.account_number}
          </p>
        </div>
      </div>
      <div className="flex w-ful break-all items-center justify-end dark:text-white text-paragraph-text text-sm font-medium leading-tight tracking-tight">
        <span className="px-1">
          {GetCurrenySymbol(account.currency_code, "14", "14")}
        </span>
        {FormatBalance(account.balance, account.is_crypto)}
      </div>
    </div>
  );
};

export default Accounts;
