import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "../../hooks";
import { resetPassword } from "../../redux/actions/ResetPassword";
import { FormInputField } from "../../components";
import CommonButton from "../../components/Buttons/CommonButton";
import Footer from "../../components/Footer";
import { Logo } from "../../components/Icons";
import { forgotPassword } from "../../redux/actions/ForgetPassword";
import OtpInputs from "./partials/OtpInputs";
import LoadingModal from "../../components/Modals/LoadingModal";
import { SmallButton } from "../../components/Buttons";
import CustomTransition from "../../components/CustomTransition";
import { setPageTitle } from "../../helper/metaDataHelper";
import PasswordToggleIcon from "../../components/Common/PasswordToggleIcon";
import ErrorPage from "../../components/ErrorPage";

const ResetPasswordPage = () => {
  const allRestPasswordPage = localStorage.getItem("allRestPasswordPage");
  const forgetenEmail = localStorage.getItem("forgetenEmail");

  if (allRestPasswordPage && forgetenEmail) {
    return <ResetPassword />;
  } else {
    return <ErrorPage />;
  }
};

const ResetPassword = () => {
  const new_password = useForm("", "password");
  const new_password_confirmation = useForm("", "password");
  const [isLoading, setisLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [Error, setError] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [counter, setCounter] = useState(60);
  const forgetenEmail = localStorage.getItem("forgetenEmail");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  useEffect(() => {
    setPageTitle("Reset Password");
  }, []);

  useEffect(() => {
    if (!isLoading && resendDisabled) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            setResendDisabled(false);
            clearInterval(timer);
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isLoading, resendDisabled]);

  const handleOTP = async () => {
    setisLoading(true);

    try {
      const data = {
        otp: otp.join(""),
        new_password: new_password.value,
        new_password_confirmation: new_password_confirmation.value,
        email: forgetenEmail.email_address,
      };
      const res = await dispatch(resetPassword(data));
      if (!res.status) {
        setError(res.error);
        setisLoading(false);
        return;
      }

      navigate("/signin");
    } catch (error) {
      setError(error);
    } finally {
      setisLoading(false);
    }
  };

  const isloadingModal = (status) => {
    setisLoading(status);
    setIsModalOpen(status);
  };

  const handleResendCode = async () => {
    isloadingModal(true);
    setResendDisabled(true);
    const res = await dispatch(forgotPassword({ email: forgetenEmail }));
    if (!res.status) {
      setError(res.error);
    } else {
      setCounter(60);
    }
    isloadingModal(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (new_password.validate() || new_password_confirmation.validate()) {
      return;
    }

    isloadingModal(true);
    const res = await dispatch(
      resetPassword({
        email: localStorage.getItem("forgetenEmail"),
        otp: otp.join(""),
        new_password: new_password.value,
        new_password_confirmation: new_password_confirmation.value,
      })
    );
    if (!res.success) {
      setError(res.error);
      isloadingModal(false);
      return;
    } else {
      localStorage.removeItem("forgetenEmail");
      localStorage.removeItem("allRestPasswordPage");
      isloadingModal(false);
      navigate("/signin");
    }
  };

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4 py-8 min-h-dvh flex-1 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center justify-center max-w-[384px] w-full h-auto space-y-4">
          <div className="mb-5">
            <Logo className="text-gray-500 max-md:w-52 max-md:h-auto w-[257px] h-[71px]" />
          </div>

          <div className="flex flex-col items-center justify-center w-full">
            <p className="text-body-text text-4xl self-start font-medium tracking-tight">
              Reset your password
            </p>

            <div className="w-full pt-[42px] space-y-6 flex flex-col items-center">
              <OtpInputs
                handleOTP={handleOTP}
                otp={otp}
                setOtp={setOtp}
                error={Error}
                setError={setError}
              />
              <div className="w-full">
                <CustomTransition show={otp.join("").length > 5}>
                  <div className="w-full space-y-4">
                    <FormInputField
                      id={"password"}
                      label={"Password"}
                      type={showPassword.password ? "text" : "password"}
                      placeholder={"Enter your password"}
                      {...new_password}
                      icon={
                        <PasswordToggleIcon
                          isVisible={showPassword.password}
                          onToggle={() => togglePasswordVisibility("password")}
                        />
                      }
                    />
                    <FormInputField
                      id={"confirm_password"}
                      label={"Confirm Password"}
                      type={showPassword.confirmPassword ? "text" : "password"}
                      placeholder={"Comfirm your password"}
                      {...new_password_confirmation}
                      icon={
                        <PasswordToggleIcon
                          isVisible={showPassword.confirmPassword}
                          onToggle={() =>
                            togglePasswordVisibility("confirmPassword")
                          }
                        />
                      }
                    />

                    <CommonButton
                      disabled={
                        !new_password.value ||
                        !new_password_confirmation.value ||
                        !otp.join("").length > 5 ||
                        isLoading
                      }
                      onClick={handleResetPassword}
                    >
                      Reset Password {isLoading && "...."}
                    </CommonButton>
                  </div>
                </CustomTransition>
              </div>
              <div className="">
                <SmallButton
                  disabled={resendDisabled}
                  onClick={handleResendCode}
                  text={`Resend OTP ${counter ? "(" + counter + ")" : ""}`}
                />
              </div>
            </div>
          </div>

          <Footer />

          {/* Second sections */}
          {/* disable this for monfi anad asia bank */}
          {/* <Slider /> */}
        </div>
      </div>
      <LoadingModal
        isLoading={isLoading}
        setisLoading={setisLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </main>
  );
};

export default ResetPasswordPage;
