import React from "react";

const CryptoWithdrawIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.92914 16.63C3.73914 16.63 3.54914 16.56 3.39914 16.41C3.10914 16.12 3.10914 15.64 3.39914 15.35L15.3491 3.4C15.6391 3.11 16.1191 3.11 16.4091 3.4C16.6991 3.69 16.6991 4.17 16.4091 4.46L4.45914 16.42C4.31914 16.56 4.11914 16.63 3.92914 16.63Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M11.0991 19.03C10.9091 19.03 10.7191 18.96 10.5691 18.81C10.2791 18.52 10.2791 18.04 10.5691 17.75L11.7691 16.55C12.0591 16.26 12.5391 16.26 12.8291 16.55C13.1191 16.84 13.1191 17.32 12.8291 17.61L11.6291 18.81C11.4891 18.95 11.2991 19.03 11.0991 19.03Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M13.7905 16.34C13.6005 16.34 13.4105 16.27 13.2605 16.12C12.9705 15.83 12.9705 15.35 13.2605 15.06L15.6505 12.67C15.9405 12.38 16.4205 12.38 16.7105 12.67C17.0005 12.96 17.0005 13.44 16.7105 13.73L14.3205 16.12C14.1805 16.26 13.9805 16.34 13.7905 16.34Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M11.1009 22.75C10.1209 22.75 9.14089 22.15 7.95089 20.96L3.04089 16.05C0.650891 13.66 0.660891 12.12 3.07089 9.71001L9.71089 3.07001C12.1209 0.660006 13.6609 0.650006 16.0509 3.04001L20.9609 7.95001C23.3509 10.34 23.3409 11.88 20.9309 14.29L14.2909 20.93C13.0809 22.14 12.0909 22.75 11.1009 22.75ZM12.9009 2.75001C12.3809 2.75001 11.7209 3.18001 10.7709 4.13001L4.13089 10.77C3.18089 11.72 2.75089 12.38 2.75089 12.89C2.75089 13.41 3.15089 14.04 4.10089 14.99L9.01089 19.9C9.96089 20.85 10.5809 21.25 11.1009 21.25C11.1009 21.25 11.1009 21.25 11.1109 21.25C11.6309 21.25 12.2809 20.82 13.2309 19.87L19.8709 13.23C20.8209 12.28 21.2509 11.62 21.2509 11.11C21.2509 10.59 20.8509 9.96001 19.9009 9.01001L14.9909 4.10001C14.0509 3.15001 13.4209 2.75001 12.9009 2.75001Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
        <path
          d="M22 22.75H2C1.59 22.75 1.25 22.41 1.25 22C1.25 21.59 1.59 21.25 2 21.25H22C22.41 21.25 22.75 21.59 22.75 22C22.75 22.41 22.41 22.75 22 22.75Z"
          className="dark:fill-icon-btn fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default CryptoWithdrawIcon;
