import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FormInputField from "../../../FormInputField";
import { PrimaryButton } from "../../../Buttons";
import { uploadProfilePicture } from "../../../../redux/actions/Settings/ProfileDetails";
import LoadingModal from "../../../Modals/LoadingModal";
import { fetchUserInfo } from "../../../../redux/actions/home/UserInfoAction";
import ToolTip from "../../../Header/ToolTip";
import { CameraIcon } from "../../../Icons";

const ProfileDetails = ({
  userInfo,
  loading,
  setLoading,
  success,
  setSuccess,
  isModalOpen,
  setIsModalOpen,
}) => {
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);

  const [profile, setProfile] = useState({
    profile_photo: null,
    profile_photo_url: userInfo.profile_photo_url,
  });

  const handleProfilePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfile((prevState) => ({
          ...prevState,
          profile_photo: e.target.files[0],
          profile_photo_url: event.target.result,
        }));
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleDetails = async () => {
    if (profile.profile_photo) {
      setIsModalOpen(true);
      setLoading(true);
      const formData = new FormData();
      formData.append("profile_photo", profile.profile_photo);

      const res = await dispatch(uploadProfilePicture(formData));

      if (res.success) {
        setLoading(false);
        setSuccess(true);

        await dispatch(fetchUserInfo());

        return;
      }
      setIsModalOpen(false);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col space-y-4 mx-2 max-[360px]:mx-0">
      <div className="inline-flex items-center">
        <label className="flex items-center cursor-pointer">
          <input
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleProfilePictureChange}
          />
          <div
            className="relative group"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={() => setShowTooltip(false)}
          >
            {profile.profile_photo_url ? (
              <img
                className="h-11 w-11 rounded-full border-2 border-divider-border"
                src={
                  profile.profile_photo_url
                    ? profile.profile_photo_url
                    : "assets/gifs/loader.gif"
                }
                alt="profile_picture"
              />
            ) : (
              <img
                className="h-11 w-11 rounded-full border-2 border-divider-border"
                src={require("../../../../assets/images/profile.png")}
                alt="profile_picture"
              />
            )}
            <div className="absolute inset-0 flex items-center justify-center bg-[#E9E8E3E5] dark:bg-[#4d4c4ae5] opacity-0 group-hover:opacity-90 rounded-full transition-opacity">
              <CameraIcon />
            </div>
            <ToolTip
              text="Change account photo"
              showTooltip={showTooltip}
              className="z-10 min-w-max"
            />
          </div>
          <span className="text-sm text-primary-item font-medium ml-3 underline">
            Add or update account photo
          </span>
        </label>
      </div>
      <div className="flex flex-col md:w-[384px] w-full space-y-4">
        <FormInputField
          label="Full name"
          name="name"
          id="name"
          type="text"
          placeholder={userInfo.name}
          value={userInfo.name}
          onChange={() => {}}
        />
        <FormInputField
          label="Email"
          name="email"
          id="email"
          type="email"
          placeholder={userInfo.email}
          value={userInfo.email}
          readOnly
          onChange={() => {}}
          isDisabled={true}
        />
        <div className="mt-6"></div>
        <PrimaryButton
          onClick={handleDetails}
          text="Save Changes"
          disabled={!profile.profile_photo}
        />
      </div>
      <LoadingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isSuccess={success}
        setSuccess={setSuccess}
        isLoading={loading}
        setisLoading={setLoading}
        title={"Profile details updated"}
        description={"Your profile details updated successfully."}
      />
    </div>
  );
};

export default ProfileDetails;
