import React from "react";
import ReceiveArrowIcon from "../ReceiveArrowIcon";

const CardDepositIcon = () => {
  return (
    <div>
      <div className="w-[42px] h-[42px] relative">
        <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-secondary-icon-bg rounded-full">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Topup-icon">
              <g id="card-add">
                <path
                  id="Vector"
                  d="M2 8.5H13.5"
                  stroke="#6A726C"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M6 13.5H10"
                  stroke="#6A726C"
                  strokeWidth={2}
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M22 12.03V14.11C22 17.62 21.11 18.5 17.56 18.5H6.44C2.89 18.5 2 17.62 2 14.11V7.89C2 5.38 2.89 4.5 6.44 4.5H13.5"
                  stroke="#6A726C"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_4"
                  d="M16.5 7.25H22"
                  stroke="#6A726C"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
                <path
                  id="Vector_5"
                  d="M19.25 10V4.5"
                  stroke="#6A726C"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </g>
            </g>
          </svg>
        </div>

        <div className="w-4 h-4 right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
          <ReceiveArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default CardDepositIcon;
