import React, { useState } from "react";
import { SwitchUserIcon } from "../../Icons";
import ToolTip from "../../Header/ToolTip";
import { SwitchUser } from "../../../redux/actions/auth";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useDispatch } from "react-redux";

const SwitchUserButton = ({ user }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSwitchUser = async () => {
    setShowTooltip(false);
    setLoading(true);
    const data = {
      key: user.key,
      impersonator: user.impersonator,
      impersonated: user.impersonated,
    };
    const res = await dispatch(SwitchUser(data));
    if (res.success) {
      setLoading(false);
      return;
    } else {
      console.error("Error in switching user");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="relative cursor-pointer">
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onClick={handleSwitchUser}
          className="active:bg-active-state-bg bg-primary-Icon-bg group hover:bg-hover-state-bg flex justify-center items-center rounded-full space-x-3 py-2 px-4 cursor-pointer"
        >
          <SwitchUserIcon className="w-8 h-8 text-primary-item group-hover:bg-active-state-bg rounded-full" />

          <label className="cursor-pointer max-[445px]:hidden max-[576px]:pl-2 text-primary-item text-sm font-medium leading-tight tracking-tight pr-0.5">
            Switch
          </label>
          <ToolTip
            text="Switch User"
            showTooltip={showTooltip}
            className="top-12 min-w-max"
          />
        </div>
      </div>
      {loading && <LoadingSpinner />}
    </>
  );
};

export default SwitchUserButton;
