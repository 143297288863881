import React, { useState } from "react";
import { DownArrowIcon, SearchIcons } from "../../Icons";

const CountriesInput = ({
  countries,
  SelectedCountry,
  onChange,
  placeholder,
  label,
  phonecode = true,
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
    setSearchQuery("");
  };

  const filteredCountries = countries.filter(
    (country) =>
      country.iso3.toLowerCase().includes(searchQuery.toLowerCase()) ||
      country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      country.phonecode.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleKeyDown = (e, countryItem) => {
    switch (e.key) {
      case "Enter":
        handleSelect(countryItem);
        break;
      case "ArrowDown":
        e.preventDefault();
        const nextElement = e.target.nextElementSibling;
        if (nextElement) {
          nextElement.focus();
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        const prevElement = e.target.previousElementSibling;
        if (prevElement) {
          prevElement.focus();
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="w-full cursor-pointer bg-input-bg border border-divider-border shadow-sm rounded-xl flex justify-between items-center py-2.5 px-3 focus:border-primary-item outline-none"
      >
        {SelectedCountry ? (
          children
        ) : (
          <div className="flex justify-between w-full items-center">
            <div>
              <p className={"block text-xs font-medium text-body-text"}>
                {label}
              </p>
              <p className="text-paragraph-text text-xs">{placeholder}</p>
            </div>
            <DownArrowIcon className="w-4 h-4 mr-2" />
          </div>
        )}
      </div>

      {isOpen && (
        <ul className="absolute flex z-10 flex-col space-y-4 mt-2 w-[340px] max-h-[260px] hide-scrollbar overflow-scroll md:w-[360px] h-auto bg-popup-bg border border-divider-border rounded-xl shadow-box-shadow p-2">
          <li>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSelect(filteredCountries[0]);
                return;
              }}
            >
              <div className="max-w-full px-3 py-1 bg-search-bg rounded-full flex justify-start items-center">
                <SearchIcons className="w-6 h-6 relative" />
                <input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  placeholder="Search"
                  autoFocus={true}
                  className="w-full text-paragraph-text text-sm font-normal bg-transparent border-none focus:ring-0 tracking-tight"
                />
              </div>
            </form>
          </li>

          {filteredCountries.map((countryItem) => (
            <li
              tabIndex={0}
              key={countryItem.id}
              className={`
             ${
               countryItem.id === SelectedCountry.id &&
               "dark:bg-option-list-selected bg-active-state-bg"
             }
             flex justify-between cursor-pointer px-4 py-3 text-sm font-medium text-body-text hover:bg-hover-state-bg rounded-xl
             bg-transparent outline-none focus:outline-primary-item focus:outline-2 focus:bg-hover-state-bg items-center
           `}
              onClick={() => handleSelect(countryItem)}
              onKeyDown={(e) => handleKeyDown(e, countryItem)}
            >
              <div className="flex space-x-3 items-center">
                <img
                  className="rounded-full w-8 h-8"
                  src={countryItem.flag}
                  alt="flag"
                />
                <span>{countryItem.name}</span>
              </div>
              {phonecode && <span>+({countryItem.phonecode})</span>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CountriesInput;
