import React, { useCallback, useEffect } from "react";
import { FormatDate, GetTransactionsTypeIcon } from "../../../../helper";
import EmptyRecentTransactions from "../../../Empty/EmptyRecentTransactions";
import CardStatementTableSkeleton from "../../../Skeleton/CardStatementTableSkeleton";
import LoadMoreTransactions from "../../../Skeleton/LoadMoreTransactions";

const CardStatementTable = ({
  transactions,
  handleSelectTransaction,
  activeTransaction,
  isLoading,
  type,
  scrollRef,
  loadMoreTransactions,
  hasMore,
  loading,
  setPage,
  page,
  loadMore,
}) => {
  const handleScroll = useCallback(() => {
    const container = scrollRef.current;
    let isScrollToBottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10;
    if (isScrollToBottom && hasMore && !loading) {
      setPage((prev) => prev + 1);
      loadMoreTransactions(page + 1);
    }
  }, [hasMore, loading, page, loadMoreTransactions, setPage, scrollRef]);

  useEffect(() => {
    const container = scrollRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={scrollRef}
      className="shadow-sm md:h-[670px] h-[470px] min-w-full overflow-y-auto rounded-3xl bg-primarycard-gradient hide-scrollbar"
    >
      {isLoading ? (
        <CardStatementTableSkeleton />
      ) : (
        <>
          <table className="w-full">
            <thead>
              <tr>
                {["Date", "Transaction", "Amount"].map((heading, index) => (
                  <th
                    key={index}
                    className={`bg-primarycard-gradient dark:bg-table-gradient sticky top-0 border-b border-divider-border z-[1] py-3.5 pl-4 pr-3 text-left text-xs font-medium text-paragraph-text sm:pl-6 lg:pl-8 ${
                      heading === "Date" ? "hidden md:table-cell" : ""
                    }`}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-primarycard-gradient">
            <tr className="h-2"></tr>
              {transactions.map((transaction, index) => {
                const isActive = activeTransaction?.id === transaction.id;
                const transactionDate = FormatDate(
                  transaction.date,
                  "d m, yyyy"
                );
                const isCredit = transaction.type === "Credit";

                return (
                  <React.Fragment key={transaction.id}>
                    <tr
                      onClick={() => handleSelectTransaction(transaction)}
                      className={`cursor-pointer hover:bg-hover-state-bg ${
                        isActive ? "bg-active-state-bg" : ""
                      }`}
                    >
                      <td className="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-body-text sm:pl-6 rounded-l-3xl">
                        <span>
                          {transactionDate.split(",")[0]}
                          {transactionDate.split(",")[1]}
                        </span>
                      </td>

                      <td className="max-w-[200px] py-4 md:pl-6 text-sm font-medium text-body-text md:table-cell md:rounded-none rounded-l-3xl pl-3">
                        <div className="flex items-center justify-start w-full">
                          {GetTransactionsTypeIcon(type, transaction.type)}

                          <p className="px-3 line-clamp-1 text-sm font-medium w-full">
                            {transaction.remarks}
                          </p>
                        </div>
                      </td>

                      <td className={`
                         ${transaction.type === "Credit" ? "text-highlighter-green" : "text-highlighter-red"}
                        whitespace-nowrap py-4 sm:pl-4 pr-3 text-sm font-semibold rounded-r-3xl`}>
                        <div className="flex space-x-1">
                          <span>{isCredit ? "+ " : "- "}</span>
                          <p className="truncate max-w-14 md:max-w-24 text-sm w-full">
                            {transaction.amount}
                          </p>
                        </div>
                      </td>
                    </tr>
                    {index !== transactions.length - 1 && (
                      <tr className="h-2"></tr> // Spacer row
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {transactions.length === 0 && (
            <div className="flex items-center justify-center h-3/4">
              <EmptyRecentTransactions />
            </div>
          )}
        </>
      )}
       {loadMore && <LoadMoreTransactions />}
    </div>
  );
};

export default CardStatementTable;
