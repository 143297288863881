import React, { Fragment, useMemo, useState } from "react";
import { Menu } from "@headlessui/react";
import ToolTip from "../ToolTip";
import { BellIcon, TrashIcon } from "../../Icons";
import SmallButton from "../../Buttons/SmallButton";
import {
  deleteAllNotification,
  deleteNotification,
  readAllNotification,
} from "../../../redux/actions/notification";
import EmptyNotification from "../../Empty/EmptyNotification"; // Imported here
import { useDispatch, useSelector } from "react-redux";
import CustomTransition from "../../CustomTransition";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Notifications = ({ getUserNotification }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);

  const handleReadAllNotification = () => {
    dispatch(readAllNotification());
  };

  const handleDeleteNotification = (notificationId) => {
    dispatch(deleteNotification(notificationId));
  };

  const handleDeleteAllNotification = () => {
    dispatch(deleteAllNotification());
  };

  const unreadNotificationCount = useMemo(() => {
    return notifications.filter((item) => item.status === "unread").length;
  }, [notifications]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          {({ open }) => (
            <>
              <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={() => setShowTooltip(false)}
                className={classNames(
                  open
                    ? "bg-active-state-bg"
                    : "hover:bg-hover-state-bg bg-primary-Icon-bg",
                  "w-12 h-12 px-3 py-[7px] rounded-[100px] justify-center items-center gap-2.5 inline-flex"
                )}
              >
                <BellIcon className="h-6 w-6 text-primary-Icon-bg" />
                {unreadNotificationCount > 0 && (
                  <span
                    className={classNames(
                      "bg-highlighter-red rounded-full text-white text-xs absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 animate-pulse flex justify-center items-center",
                      unreadNotificationCount > 99
                        ? "w-[26px] h-[26px] text-[10px]"
                        : unreadNotificationCount > 9
                        ? "w-5 h-5"
                        : "w-4 h-4"
                    )}
                  >
                    {unreadNotificationCount > 99
                      ? "99+"
                      : unreadNotificationCount}
                  </span>
                )}
              </div>
              <ToolTip text="Notification" showTooltip={showTooltip} />
            </>
          )}
        </Menu.Button>
      </div>

      <CustomTransition
        as={Fragment}
        enter="transition ease-out duration-500"
        enterFrom="transform opacity-0 scale-95"
        leave="transition ease-in duration-75"
      >
        <Menu.Items className="absolute w-[360px] max-h-[592px] bg-popup-bg rounded-3xl shadow right-0 z-10 mt-2 min-w-max origin-top-right divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none px-4 overflow-scroll hide-scrollbar">
          <div className="flex justify-between items-center sticky top-0 bg-popup-bg z-40 pb-1">
            <p className="text-body-text text-2xl font-semibold leading-loose tracking-tight">
              Notifications
            </p>
            <SmallButton
              onClick={handleReadAllNotification}
              text="Mark all as read"
            />
          </div>
          <div className="relative">
            {notifications.length === 0 ? (
              <EmptyNotification getUserNotification={getUserNotification} />
            ) : (
              <>
                {notifications.map((item) => (
                  <Notification
                    key={item.id}
                    notificationId={item.id}
                    photo_url={item.photo_url}
                    title={item.title}
                    message={item.message}
                    status={item.status}
                    date={item.date}
                    onDelete={handleDeleteNotification}
                  />
                ))}
                <div className="flex sticky justify-between bottom-0 bg-popup-bg">
                  <span></span>
                  <SmallButton
                    onClick={handleDeleteAllNotification}
                    text="Delete all"
                  />
                </div>
              </>
            )}
          </div>
        </Menu.Items>
      </CustomTransition>
    </Menu>
  );
};

const Notification = ({
  notificationId,
  photo_url,
  title,
  message,
  status,
  date,
  onDelete,
}) => {
  const [showTrashIcon, setShowTrashIcon] = useState(false);

  const handleNotificationClick = (e) => {
    e.preventDefault();
    setShowTrashIcon((prev) => !prev);
  };

  return (
    <div className="my-2 cursor-pointer">
      <Menu.Item>
        <div
          onClick={handleNotificationClick}
          className={classNames(
            status === "unread" ? "bg-primary-Icon-bg" : "bg-transparent",
            "w-full p-3 rounded-3xl justify-start items-start gap-4 inline-flex relative"
          )}
        >
          <div
            className={classNames(
              status === "unread" ? "bg-white" : "bg-gray-300",
              "w-10 h-10 relative rounded-full justify-center flex items-center"
            )}
          >
            {photo_url ? (
              <img
                className="rounded-full"
                src={photo_url}
                alt="notification"
              />
            ) : (
              <img
                className="rounded-full"
                src={require("../../../assets/images/profile.png")}
                alt="notification"
              />
            )}
            {status === "unread" && (
              <span className="w-2.5 h-2.5 left-[28px] top-[1px] absolute bg-highlighter-red rounded-full animate-pulse" />
            )}
          </div>
          <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className="text-body-text text-sm font-medium leading-tight tracking-tight">
              {title}
            </div>
            <div className="w-[184px] text-paragraph-text text-sm font-normal leading-tight tracking-tight">
              {message}
            </div>
            <div className="text-paragraph-text opacity-80 text-xs font-normal leading-tight tracking-tight">
              {date}
            </div>
          </div>
          {showTrashIcon && (
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <TrashIcon
                className="w-6 h-6"
                onClick={() => onDelete(notificationId)}
              />
            </div>
          )}
        </div>
      </Menu.Item>
    </div>
  );
};

export default Notifications;
